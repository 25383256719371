import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  List,
  ShowButton,
  TextField,
} from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { CompaniesListActions } from './ui/actions';

export const CompaniesList = () => {
  return (
    <List actions={<CompaniesListActions />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label={LABELS.companyName} sortable={false} />
        <EmailField source="owner.email" label={LABELS.email} />
        <DateField
          source="createdAt"
          label="Дата добавления"
          showTime
          sortable={false}
        />
        <BooleanField source="isArchive" label="В архиве" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
