import { Create, required, SimpleForm, TextInput } from 'react-admin';
import { LABELS } from 'shared/constants/labels';

export const FaqGroupCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="title"
          label={LABELS.title}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
