import {
  BooleanField,
  EmailField,
  ImageField,
  SimpleShowLayout,
  TextField,
  useShowContext,
  WrapperField,
} from 'react-admin';
import { LABELS } from 'shared/constants/labels';

const Owner = () => {
  const { record } = useShowContext();

  return (
    <SimpleShowLayout>
      <TextField source="owner.id" label="ID" />
      <EmailField source="owner.email" label={LABELS.email} />
      <TextField source="owner.phone" label={LABELS.phone} />
      <TextField source="owner.firstname" label={LABELS.firstname} />
      <TextField source="owner.lastname" label={LABELS.lastname} />
      <BooleanField
        source="owner.isEmailConfirmed"
        label={LABELS.isEmailConfirmed}
      />
      <TextField source="owner.gender" label={LABELS.gender} />
      <WrapperField label={LABELS.avatarKey}>
        <a href={record?.owner.avatarKey} target="_blank">
          <ImageField
            source="owner.avatarKey"
            sx={{
              '& .RaImageField-image': {
                width: 300,
                height: 300,
                objectFit: 'cover',
              },
            }}
          />
        </a>
      </WrapperField>
    </SimpleShowLayout>
  );
};

export default Owner;
