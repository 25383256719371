import { useState } from 'react';
import {
  ArrayInput,
  Create,
  DateInput,
  email,
  FormTab,
  HttpError,
  ImageField,
  ImageInput,
  NumberInput,
  PasswordInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useNotify,
} from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { VARS } from 'shared/constants/vars';
import { httpClient } from 'shared/services/http-client';
import { uploadFile } from 'shared/utils/upload-file';

const promocodeExpireDateDefaultValue = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
).toISOString();

export const CompaniesCreate = () => {
  const notify = useNotify();
  const [isReadOnly, setIsReadOnly] = useState(true);

  const handleSubmit = async ({ promocodeExpireDate, ...data }: any) => {
    if (data.user.avatarKey?.rawFile) {
      const uploadedFile = await uploadFile(data.user.avatarKey?.rawFile);

      if (uploadedFile.key) {
        data.user.avatarKey = uploadedFile.key;
        const expireDate = new Date(promocodeExpireDate);
        expireDate.setHours(15);

        try {
          await httpClient(`${API_BASE_URL_ADMIN}${API_URL.createCompany}`, {
            method: 'POST',
            body: JSON.stringify({
              ...data,
              promocodeExpireDate: expireDate.toISOString(),
            }),
          });

          notify('Новая компания создана', { type: 'success' });
        } catch (err) {
          const error = err as HttpError;
          notify(error?.message ?? 'Что-то пошло не так...', {
            type: 'error',
          });
        }
      }
    }
  };

  return (
    <Create title="New company">
      <TabbedForm
        onSubmit={handleSubmit}
        onFocus={() => isReadOnly && setIsReadOnly(false)}
      >
        <FormTab label="Пользователь">
          <TextInput
            label={LABELS.firstname}
            source="user.firstname"
            validate={[required()]}
            fullWidth
            inputProps={{ readOnly: isReadOnly }}
          />
          <TextInput
            label={LABELS.lastname}
            source="user.lastname"
            validate={[required()]}
            fullWidth
            inputProps={{ readOnly: isReadOnly }}
          />
          <TextInput
            label={LABELS.email}
            source="user.email"
            validate={[required(), email()]}
            fullWidth
            inputProps={{ readOnly: isReadOnly }}
          />
          <TextInput
            label="Request id"
            source="requestId"
            validate={[required()]}
            fullWidth
            inputProps={{ readOnly: isReadOnly }}
          />
          <PasswordInput
            label={LABELS.password}
            source="user.password"
            validate={[required()]}
            fullWidth
            inputProps={{ readOnly: isReadOnly }}
          />
          <ImageInput
            label={LABELS.avatarKey}
            source="user.avatarKey"
            validate={[required()]}
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label="Компания">
          <TextInput
            label={LABELS.companyName}
            source="company.name"
            validate={[required()]}
            fullWidth
          />
        </FormTab>
        <FormTab label="Промокоды">
          <ArrayInput source="promocodes" defaultValue={[]}>
            <SimpleFormIterator>
              <SelectInput
                label={LABELS.promocodeAmount}
                source="amount"
                validate={[required()]}
                fullWidth
                choices={VARS.promocodesChoices}
              />
              <NumberInput
                label={LABELS.promocodeQuantity}
                source="quantity"
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
          <DateInput
            label={LABELS.promocodeExpireDate}
            source="promocodeExpireDate"
            defaultValue={promocodeExpireDateDefaultValue}
            fullWidth
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
