import { Button } from '@mui/material';
import { useCallback } from 'react';
import {
  TopToolbar,
  useCreatePath,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { AdminTherapistProfileUpdateResponseDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';
import { ResourseNames } from 'shared/types';

export const TherapistProfileUpdateShowActions = () => {
  const createPath = useCreatePath();

  const record = useRecordContext<AdminTherapistProfileUpdateResponseDto>();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleAcceptButtonClick = useCallback(() => {
    httpClient(
      `${API_BASE_URL_ADMIN}${API_URL.therapistProfileUpdateAccept(
        record.id as string,
      )}`,
      {
        method: 'PATCH',
      },
    )
      .then(() => {
        notify('Обновления приняты', { type: 'success' });
        redirect('/therapists/profile-updates');
      })
      .catch((error) => {
        notify(error.message || 'Что-то пошло не так...', {
          type: 'error',
        });
      });
  }, [notify, record.id, redirect]);

  const handleRejectButtonClick = useCallback(() => {
    httpClient(
      `${API_BASE_URL_ADMIN}${API_URL.therapistProfileUpdateReject(
        record.id as string,
      )}`,
      {
        method: 'PATCH',
      },
    )
      .then(() => {
        notify('Обновления отклонены', { type: 'success' });
        redirect('/therapists/profile-updates');
      })
      .catch((error) => {
        notify(error.message || 'Что-то пошло не так...', {
          type: 'error',
        });
      });
  }, [notify, record.id, redirect]);

  const handleClickProfileTherapistButton = useCallback(() => {
    redirect(
      createPath({
        resource: ResourseNames.therapists,
        type: 'show',
        id: record.therapistId,
      }),
    );
  }, [createPath, record.therapistId, redirect]);

  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="info"
        size="small"
        onClick={handleClickProfileTherapistButton}
      >
        Посмотреть профиль
      </Button>
      <Button
        variant="contained"
        color="success"
        size="small"
        onClick={handleAcceptButtonClick}
      >
        Принять
      </Button>
      <Button
        variant="contained"
        color="error"
        size="small"
        onClick={handleRejectButtonClick}
      >
        Отказать
      </Button>
    </TopToolbar>
  );
};
