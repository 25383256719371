import { FC, useState } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  ListContextProvider,
  Pagination,
  TextField,
  useList,
} from 'react-admin';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { API_URL } from 'shared/constants/api-url';
import { AdminSessionsResponseDto } from 'shared/api';
import RowActions from './ui/row-actions';

interface Props {
  therapistId: string;
}

const TherapistSessions: FC<Props> = ({ therapistId }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading, refetch } = useHttpClient<AdminSessionsResponseDto>(
    `${API_URL.therapistSessions}?therapistId=${therapistId}&limit=${perPage}&page=${page}`,
  );
  const listContext = useList({
    data: data?.data ?? [],
    isLoading,
    page,
    perPage,
    sort: { field: 'availability.startDate', order: 'DESC' },
  });

  return (
    <>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="status" sortable={false} label="Статус сессии" />
          <TextField
            source="cancelReason"
            sortable={false}
            label="Причина отмены сессии"
          />
          <DateField
            source="availability.startDate"
            showTime
            label="Дата начала сессии"
          />
          <TextField source="patients[0].phone" label="Телефон клиента" />
          <TextField
            source="payment.status"
            sortable={false}
            label="Статус оплаты"
          />
          <TextField
            source="payment.payoutStatus"
            sortable={false}
            label="Статус выплаты"
          />
          <BooleanField
            source="isTherapistJoined"
            label="Посещено терапевтом"
          />
          <TextField source="payment.value" label="Оплачено клиентом" />
          <TextField source="payment.discountValue" label="Доплата" />
          <TextField source="sessionRequestId" label="Session Request ID" />
          <TextField source="payment.dealId" label="Deal ID YooKassa" />
          <RowActions refetch={refetch} />
        </Datagrid>
        <Pagination
          isLoading={isLoading}
          page={page}
          perPage={perPage}
          total={data?.total}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      </ListContextProvider>
    </>
  );
};

export default TherapistSessions;
