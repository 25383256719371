import { useState } from 'react';
import { Form, NumberInput, SaveButton, TextInput, useNotify } from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';
import styled from 'styled-components';

type PaymentLink = {
  paymentLink: string;
  amount: number;
  currency: string;
}

const PaymentLinkCreate = () => {
  const [paymentLink, setPaymentLink] = useState<PaymentLink>();
  const [isLoading] = useState(false);
  const notify = useNotify();

  const createPaymentLink = async (params: { amount: number, description: string }) => {
    const response = await httpClient(
      API_BASE_URL_ADMIN + API_URL.createPaymentLink,
      {
        method: 'POST',
        body: JSON.stringify({ amount: params.amount, description: params.description }),
      },
    );

    setPaymentLink(() => response.json);
  }

  const handleSubmit = (params: any) => {
    createPaymentLink(params);
  }

  const copyPaymentLinkToClipboard = () => {
    navigator.clipboard.writeText(paymentLink!.paymentLink);
    notify('Ссылка скопирована в буфер обмена', { type: 'success' });
  }

  return (
    <>
      <Root>
        {isLoading && <p>Загрузка...</p>}
        <Form onSubmit={handleSubmit}>
          <div>
            <NumberInput
              label="Стоимость в рублях"
              source={'amount'}
              style={{ marginRight: '10px' }}
            />
            <TextInput
              label="Описание"
              source={'description'}
              style={{ marginRight: '10px' }}
            />
          </div>
          <SaveButton label="Создать" disabled={isLoading} />
        </Form>
        {paymentLink &&
          <PaymentLinkStyled>
            <span>{paymentLink.amount}</span>&nbsp;
            <span>{paymentLink.currency.toUpperCase()}</span>&nbsp;
            <span style={{ color: 'blue', cursor: 'pointer' }} onClick={copyPaymentLinkToClipboard}>Ссылка на оплату</span>
          </PaymentLinkStyled>
        }
      </Root>
    </>
  );
};

export default PaymentLinkCreate;

const Root = styled.div`
  padding: 20px;
`;

const PaymentLinkStyled = styled.div`
  display: flex;
  margin-top: 20px;
`;
