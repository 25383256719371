import { API_BASE_URL } from "shared/config";
import { API_URL } from "shared/constants/api-url";
import { httpClient } from "shared/services/http-client";
import objectToQueryParams from "shared/utils/object-to-query-params";

async function check( promoCode: string, patientUserId: string ) {

  const params = {

    code: promoCode,
    userId: patientUserId

  }

  const apiUrl = (
    
    `${API_BASE_URL}/${API_URL.promoCodeCheck}`
    + `?${ objectToQueryParams( params ) }`
  
  );

  try {

    const { json } = await httpClient( apiUrl, { method: 'POST' } );

    if (!json) throw new Error('Произошла ошибка при получении данных');

    return json;

  } catch (err) {

    console.error(err);
    throw err;

  }
 
}

export {

  check

}
