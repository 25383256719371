import { FC } from 'react';
import { Button } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { AdminCompanyResponseDto } from 'shared/api';
import { useToggleArchiveCompany } from './model';

export const ToggleArchiveCompany: FC<AdminCompanyResponseDto> = (props) => {
  const { toggleArchiveCompany, isLoading } = useToggleArchiveCompany(props);

  const { isArchive } = props;

  return (
    <Button
      variant="contained"
      color="warning"
      startIcon={isArchive ? <UnarchiveIcon /> : <ArchiveIcon />}
      onClick={toggleArchiveCompany}
      disabled={isLoading}
    >
      {isArchive ? 'UnArchive' : 'Archive'}
    </Button>
  );
};
