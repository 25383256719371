import { Edit, required, SimpleForm, TextInput } from 'react-admin';

export const TherapyMethodsEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" fullWidth validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
};
