import { useAddPromocodes } from 'features/add-promocodes';
import {
  ArrayInput,
  DateInput,
  Form,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  useShowContext,
} from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { VARS } from 'shared/constants/vars';
import styled from 'styled-components';

const promocodeExpireDateDefaultValue = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 6,
).toISOString();

const AddPromocodesForm = () => {
  const { record } = useShowContext();
  const { addPromocodes, isLoading } = useAddPromocodes({
    companyId: record.id,
  });

  if (record.isArchive) {
    return (
      <Title>Вы не можете добавить промокоды архивированной компании</Title>
    );
  }

  const handleSubmit = ({ promocodes, promocodeExpireDate }: any) => {
    const expireDate = new Date(promocodeExpireDate);
    expireDate.setHours(15);
    addPromocodes({
      promocodes,
      promocodeExpireDate: expireDate.toISOString(),
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ArrayInput source="promocodes" defaultValue={[]} validate={[required()]}>
        <SimpleFormIterator>
          <SelectInput
            label={LABELS.promocodeAmount}
            source="amount"
            validate={[required()]}
            fullWidth
            choices={VARS.promocodesChoices}
          />
          <NumberInput
            label={LABELS.promocodeQuantity}
            source="quantity"
            fullWidth
          />
        </SimpleFormIterator>
      </ArrayInput>
      <DateInput
        label={LABELS.promocodeExpireDate}
        source="promocodeExpireDate"
        defaultValue={promocodeExpireDateDefaultValue}
        validate={[required()]}
        fullWidth
      />
      <SaveButton disabled={isLoading} />
    </Form>
  );
};

export default AddPromocodesForm;

const Title = styled.h2`
  text-align: center;
  font-family: sans-serif;
  color: #494848;
`;
