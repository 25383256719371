import { useUpdateReusablePromocode } from 'features/update-reusable-promocode';
import {
  BooleanInput,
  DateInput,
  Edit,
  Loading,
  NumberInput,
  SimpleForm,
  TextInput,
  useGetOne,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { LABELS } from 'shared/constants/labels';
import { useState } from 'react';

export const ReuseablePromocodesEdit = () => {
  const { id } = useParams();
  const { updateReuseablePromocode } = useUpdateReusablePromocode({
    id,
  });
  const { data, isLoading } = useGetOne('promocodes', { id });

  const hasPercentage = !isLoading && data.amount === null;

  const [isPercentagePromocode] = useState(hasPercentage);

  const handleSubmit = ({
    expireDate,
    amount,
    code,
    percentage,
    firstUseOnly,
  }: any) => {
    if (expireDate) {
      const promocodeExpireDate = new Date(expireDate);
      promocodeExpireDate.setHours(15);

      if (isPercentagePromocode) {
        updateReuseablePromocode({
          percentage,
          code,
          expireDate: promocodeExpireDate.toISOString(),
          firstUseOnly,
        });
      } else {
        updateReuseablePromocode({
          amount,
          code,
          expireDate: promocodeExpireDate.toISOString(),
          firstUseOnly,
        });
      }
    } else {
      if (isPercentagePromocode) {
        updateReuseablePromocode({
          percentage,
          code,
          firstUseOnly,
        });
      } else {
        updateReuseablePromocode({
          amount,
          code,
          firstUseOnly,
        });
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit}>
        {isPercentagePromocode ? (
          <NumberInput
            source="percentage"
            label={LABELS.promocodePercentage}
            fullWidth
            min={1}
            max={99}
          />
        ) : (
          <NumberInput
            source="amount"
            label={LABELS.promocodeAmount}
            fullWidth
          />
        )}
        <TextInput source="code" label="Именование" fullWidth />
        <DateInput
          label={LABELS.promocodeExpireDate}
          source="expireDate"
          fullWidth
        />
        <BooleanInput
          label={LABELS.promocodeFirstUseOnly}
          source="firstUseOnly"
        />
      </SimpleForm>
    </Edit>
  );
};
