import {
  DateField,
  Show,
  TabbedShowLayout,
  TextField,
  BooleanField,
  Tab,
} from 'react-admin';
import { CompaniesShowActions } from './ui/actions';
import AddPromocodesForm from './ui/add-promocodes-form';
import Owner from './ui/owner';
import Promocodes from './ui/promocodes';
import Statistic from './ui/statistic';

export const CompaniesShow = () => {
  return (
    <Show actions={<CompaniesShowActions />}>
      <TabbedShowLayout>
        <Tab label="Детали компании">
          <TextField source="id" label="ID компании" sortable={false} />
          <TextField
            source="ownerId"
            label="ID владельца компании"
            sortable={false}
          />
          <TextField source="name" label="Имя компании" sortable={false} />
          <BooleanField source="isArchive" label="В архиве" sortable={false} />
          <DateField
            source="createdAt"
            label="Дата добавления"
            sortable={false}
          />
        </Tab>
        <Tab label="Статистика">
          <Statistic />
        </Tab>
        <Tab label="Промокоды">
          <Promocodes />
        </Tab>
        <Tab label="Владелец компании">
          <Owner />
        </Tab>
        <Tab label="Добавить промокоды">
          <AddPromocodesForm />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
