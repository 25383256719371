import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
  isTest?: boolean;
  isTestUrl: string;
}

export const useToggleIsTest = ({ isTest, isTestUrl, id }: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
    isTestUrl,
    {
      id,
      data: { isTest: !isTest },
    },
    {
      onSettled: () => {
        refresh();
      },
    },
  );

  const handleToggleIsTest = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    toggle,
    handleToggleIsTest,
    isLoading,
    error,
  };
};
