import { useEffect, useState } from 'react';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import styled from 'styled-components';
import { useNotify } from 'react-admin';

const ApiKeys = () => {
  const token = localStorage.getItem('token');
  const notify = useNotify();

  const [apiKey, setApiKey] = useState<string|undefined>('...');
  const [isLoading, setIsLoading] = useState(false);

  const getApiKey = () => {
    setIsLoading(true);

    fetch(
      `${API_BASE_URL_ADMIN}${API_URL.apiKey}`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      },
    )
    .then(res => res.json())
    .then(res => {
      setApiKey(res.apiKey);
      setIsLoading(false);
    });
  }

  const handleCopyApiKeyInClipboard = () => {
    if (!apiKey) {
      throw new Error('No Api Key');
    }
    
    navigator.clipboard.writeText(apiKey);
    notify('Copied', { type: 'success' })
  }

  const handleRefreshApiKey = () => {
    setIsLoading(true);

    fetch(
      `${API_BASE_URL_ADMIN}${API_URL.apiKey}`,
      {
        method: 'PATCH',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      },
    )
    .then(res => res.json())
    .then(() => getApiKey())
    .catch(err => notify(err, { type: 'error' }));
  }

  useEffect(() => {
    getApiKey();
  }, []);

  return (
    <Root>
      <StyledApiKey>
        <StyledApiKeyText>{isLoading ? '...' : apiKey}</StyledApiKeyText>
        <StyledApiKeyCopyButton disabled={isLoading} onClick={handleCopyApiKeyInClipboard}>Copy</StyledApiKeyCopyButton>
      </StyledApiKey>
      <RefreshBtn disabled={isLoading} onClick={handleRefreshApiKey}>Обновить</RefreshBtn>
    </Root>
  );
};

export default ApiKeys;

const Root = styled.div`
  padding: 20px;
`;

const StyledApiKey = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  background-color: #eee;
`

const StyledApiKeyText = styled.div`
  margin: auto 0;
`

const StyledApiKeyCopyButton = styled.button`
  background: none;
  border: none;
  padding: 10px;
  margin: 0;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    color: white;
    background-color: #4994e8;
  }
`

const RefreshBtn = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;
