export const getValueFromTextareaWithLineBreak = (value?: string | null) => {
  if (!value) return '';

  const valueWithLineBreak = value.replace(/\n\r?/g, '<br />');

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: valueWithLineBreak,
      }}
    />
  );
};
