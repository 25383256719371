import { useEffect, useState } from 'react';
import { DateTimeInput, Form, SaveButton } from 'react-admin';
import { SessionStatisticsResponseDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import styled from 'styled-components';

const defaultStartDate = new Date().toISOString();
const defaultEndDate = new Date().toISOString();

const Statistics = () => {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [data, setData] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);

  const {
    isLoading: isLoadingStatisticsSessions,
    data: dataStatisticsSessions,
  } = useHttpClient<SessionStatisticsResponseDto>(
    `${API_URL.statisticsSessions}?startDate=${startDate}&endDate=${endDate}`,
  );

  useEffect(() => {
    const token = localStorage.getItem('token');

    setIsLoading(true);
    fetch(
      `${API_BASE_URL_ADMIN}${API_URL.promotedSessionStatistics}?startDate=${startDate}&endDate=${endDate}`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      },
    )
      .then((res) => res.blob())
      .then((result) => setData(result))
      .finally(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate]);

  if (!dataStatisticsSessions || isLoadingStatisticsSessions) return null;

  const download = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);

    const tempAnchor = document.createElement('a');
    tempAnchor.setAttribute('href', url);
    tempAnchor.setAttribute('download', 'statistics_sessions.xls');
    tempAnchor.click();
    tempAnchor.remove();
  };

  const handleSubmit = ({ startDate, endDate }: any) => {
    if (startDate && typeof startDate !== 'string')
      setStartDate(startDate?.toISOString());
    if (endDate && typeof endDate !== 'string')
      setEndDate(endDate?.toISOString());
  };

  return (
    <Root>
      <Form onSubmit={handleSubmit}>
        <div>
          <DateTimeInput
            label="Начало промежутка"
            source="startDate"
            style={{ marginRight: '10px' }}
            defaultValue={startDate}
          />
          <DateTimeInput
            label="Конец промежутка"
            source="endDate"
            defaultValue={endDate}
          />
        </div>
        <SaveButton label="Изменить" disabled={isLoading} />
      </Form>
      <DownloadBtn
        disabled={!data || isLoading}
        onClick={() => data && download(data)}
      >
        Скачать
      </DownloadBtn>
      <p>{`Количество завершенных сессий: ${dataStatisticsSessions?.completed}`}</p>
      <p>{`Количество отмененных сессий: ${dataStatisticsSessions?.canceled}`}</p>
      <p>{`Количество забронированных сессий: ${dataStatisticsSessions?.booked}`}</p>
      <p>{`Количество запланированных сессий: ${dataStatisticsSessions?.planned}`}</p>
    </Root>
  );
};

export default Statistics;

const Root = styled.div`
  padding: 20px;
`;

const DownloadBtn = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;
