import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Button } from '@mui/material';
import { FC } from 'react';
import { useToggleIsMedsi } from './model';

interface Props {
  id?: string;
  isMedsi?: boolean;
  isMedsiUrl: string;
}

export const ToggleIsMedsi: FC<Props> = ({ isMedsi, isMedsiUrl, id }) => {
  const { handleToggleIsMedsi, isLoading } = useToggleIsMedsi({
    isMedsi,
    id,
    isMedsiUrl,
  });

  return !isMedsi ? (
    <Button
      variant="contained"
      color="error"
      startIcon={<UnpublishedIcon />}
      size="small"
      onClick={handleToggleIsMedsi}
      disabled={isLoading}
    >
      MEDSI
    </Button>
  ) : (
    <Button
      variant="contained"
      color="success"
      startIcon={<CheckCircleIcon />}
      size="small"
      onClick={handleToggleIsMedsi}
      disabled={isLoading}
    >
      MEDSI
    </Button>
  );
};
