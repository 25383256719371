import { Datagrid, EditButton, List, ShowButton, TextField } from 'react-admin';

import { LABELS } from 'shared/constants/labels';
import { DiseasesListActions } from './ui/actions';

export const DiseasesList = () => {
  return (
    <List actions={<DiseasesListActions />} pagination={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label={LABELS.name} sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
