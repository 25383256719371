import { useState } from 'react';
import { useNotify } from 'react-admin';
import { CreatePromocodeRequestDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';

export const useCreateReusablePromocode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();

  const url = API_URL.createReuseablePromocode;

  const createReuseablePromocode = (data: CreatePromocodeRequestDto) => {
    setIsLoading(true);
    httpClient(`${API_BASE_URL_ADMIN}${url}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        notify('Промокод успешно создан', { type: 'success' });
      })
      .catch((error) => {
        notify(error.message || 'Что-то пошло не так...', { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { createReuseablePromocode, isLoading };
};
