import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  TextField,
  usePermissions,
} from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { saveTrimmedString } from 'shared/utils/save-trimmed-string';

export const CompaniesRequestsList = () => {
  usePermissions();
  return (
    <List>
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="id"
          label="ID заявки"
          sortable={false}
          onCopy={saveTrimmedString}
        />
        <TextField source="name" label={LABELS.companyName} sortable={false} />
        <EmailField source="email" label={LABELS.email} sortable={false} />
        <TextField source="phone" label={LABELS.phone} sortable={false} />
        <BooleanField
          source="contactByPhone"
          label={LABELS.contactByPhone}
          sortable={false}
        />
        <TextField
          source="username"
          label={LABELS.firstname}
          sortable={false}
        />
        <TextField
          source="numberOfEmployee"
          label="К-во работников"
          sortable={false}
        />
        <DateField
          source="createdAt"
          label="Дата добавления"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
