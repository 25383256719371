export const downloadText = (
  text: string,
  fileType: string | undefined = 'text/plain',
  fileName: string | undefined = 'text',
) => {
  const blob = new Blob([text], { type: fileType });
  const url = window.URL.createObjectURL(blob);

  const tempAnchor = document.createElement('a');
  tempAnchor.setAttribute('href', url);
  tempAnchor.setAttribute('download', fileName);
  tempAnchor.click();
  tempAnchor.remove();

  return;
};
