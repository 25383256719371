import { PermissionType } from 'app/providers/auth-provider';
import { Resource } from 'react-admin';
import { ResourseNames } from 'shared/types';
import { CompaniesRequestsList } from 'widgets/companies-requests/list';
import { CompaniesCreate } from 'widgets/companies/create';
import { CompaniesEdit } from 'widgets/companies/edit';
import { CompaniesList } from 'widgets/companies/list';
import { CompaniesShow } from 'widgets/companies/show';
import { DiseasesGroupCreate } from 'widgets/diseases-group/create';
import { DiseasesGroupEdit } from 'widgets/diseases-group/edit';
import { DiseasesGroupList } from 'widgets/diseases-group/list';
import { DiseasesCreate } from 'widgets/diseases/create';
import { DiseasesEdit } from 'widgets/diseases/edit';
import { DiseasesList } from 'widgets/diseases/list';
import { DiseasesShow } from 'widgets/diseases/show';
import { FaqGroupCreate } from 'widgets/faq-group/create';
import { FaqGroupEdit } from 'widgets/faq-group/edit';
import { FaqGroupList } from 'widgets/faq-group/list';
import { FaqQuestionsCreate } from 'widgets/faq-questions/create';
import { FaqQuestionsEdit } from 'widgets/faq-questions/edit';
import { FaqQuestionsList } from 'widgets/faq-questions/list';
import { PatientCreate } from 'widgets/patients/create';
import { PatientsList } from 'widgets/patients/list';
import { PatientShow } from 'widgets/patients/show';
import PromocodesStatistics from 'widgets/promocodesStatistics';
import PromotedSessionStatistics from 'widgets/promotedSessionStatistics';
import { ReuseablePromocodesCreate } from 'widgets/reusable-promocodes/create';
import { ReuseablePromocodesEdit } from 'widgets/reusable-promocodes/edit';
import ReusablePromocodesList from 'widgets/reusable-promocodes/list';
import Statistics from 'widgets/statistics';
import { TherapistProfileUpdatesList } from 'widgets/therapists-profile-updates/list';
import { TherapistProfileUpdatesShow } from 'widgets/therapists-profile-updates/show';
import { TherapistsRequestsList } from 'widgets/therapists-requests/list';
import { TherapistsRequestsShow } from 'widgets/therapists-requests/show';
import { TherapistList } from 'widgets/therapists/list';
import { TherapistsShow } from 'widgets/therapists/show';
import { TherapyMethodsCreate } from 'widgets/therapy-methods/create';
import { TherapyMethodsEdit } from 'widgets/therapy-methods/edit';
import { TherapyMethodsList } from 'widgets/therapy-methods/list';
import ApiKeys from 'widgets/api-keys';
import Webhooks from 'widgets/webhooks';
import SearchStatistics from 'widgets/search-statistics';
import ClientsStatistics from 'widgets/clients-statistics';
import Currencies from 'widgets/currencies';
import PaymentLinkCreate from 'widgets/payment-link-create';
import { PaymentLinkList } from 'widgets/payment-link-list/list';

export const resources = [
  <Resource
    name={ResourseNames.therapists}
    list={TherapistList}
    show={TherapistsShow}
  />,
  <Resource
    name={ResourseNames.therapistsRequests}
    list={TherapistsRequestsList}
    show={TherapistsRequestsShow}
  />,
  <Resource
    name={ResourseNames.therapistsProfileUpdates}
    list={TherapistProfileUpdatesList}
    show={TherapistProfileUpdatesShow}
  />,

  <Resource
    options={{ label: 'Companies' }}
    name={ResourseNames.companies}
    list={CompaniesList}
    create={CompaniesCreate}
    show={CompaniesShow}
    edit={CompaniesEdit}
  />,
  <Resource
    options={{ label: 'Запросы на создание компании' }}
    name={ResourseNames.companiesRequests}
    list={CompaniesRequestsList}
  />,
  <Resource
    name={ResourseNames.patients}
    list={PatientsList}
    show={PatientShow}
    create={PatientCreate}
  />,
  <Resource
    name={ResourseNames.diseasesGroup}
    list={DiseasesGroupList}
    edit={DiseasesGroupEdit}
    create={DiseasesGroupCreate}
  />,
  <Resource
    name={ResourseNames.diseases}
    list={DiseasesList}
    create={DiseasesCreate}
    show={DiseasesShow}
    edit={DiseasesEdit}
  />,
  <Resource
    name={ResourseNames.therapyMethods}
    list={TherapyMethodsList}
    create={TherapyMethodsCreate}
    edit={TherapyMethodsEdit}
  />,
  <Resource
    name={ResourseNames.faqGroup}
    list={FaqGroupList}
    edit={FaqGroupEdit}
    create={FaqGroupCreate}
  />,
  <Resource
    name={ResourseNames.faqQuestions}
    list={FaqQuestionsList}
    edit={FaqQuestionsEdit}
    create={FaqQuestionsCreate}
  />,
  <Resource name={ResourseNames.statisticsSessions} list={Statistics} />,
  <Resource
    name={ResourseNames.statisticsPromocodes}
    list={PromocodesStatistics}
  />,
  <Resource
    name={ResourseNames.currencies}
    list={Currencies}
  />,
  <Resource
    name={ResourseNames.statisticsClients}
    list={ClientsStatistics}
  />,
  <Resource
    name={ResourseNames.reusablePromocodes}
    list={ReusablePromocodesList}
    create={ReuseablePromocodesCreate}
	  edit={ReuseablePromocodesEdit}
  />,
  <Resource
    name={ResourseNames.webhooks}
    list={Webhooks}
  />,
  <Resource
    name={ResourseNames.apiKeys}
    list={ApiKeys}
  />,
  <Resource name={ResourseNames.statisticsPromotedSessions} list={PromotedSessionStatistics} />,
  <Resource name={ResourseNames.searchStatistics} list={SearchStatistics} />,
  <Resource
    name={ResourseNames.paymentLinkCreate}
    list={PaymentLinkCreate}
  />,
  <Resource
    name={ResourseNames.paymentLinkList}
    list={PaymentLinkList}
  />,
];

export const getResources = (permissions: PermissionType[]) => {
  return resources.filter((resource) => {
    return permissions.some((permission) => {
      if ([ResourseNames.apiKeys, ResourseNames.webhooks].includes(resource.props.name)) {
        return resource.props.name === permission.resource;
      }

      return ['*', resource.props.name].includes(permission.resource);
    });
  });
};
