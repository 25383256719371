import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Button } from '@mui/material';
import { useToggleIsTest } from 'features/toggle-is-test/model';
import { FC } from 'react';

interface Props {
  id?: string;
  isTest?: boolean;
  isTestUrl: string;
}

export const ToggleIsTest: FC<Props> = ({ isTest, isTestUrl, id }) => {
  const { handleToggleIsTest, isLoading } = useToggleIsTest({
    isTest,
    id,
    isTestUrl,
  });

  return !isTest ? (
    <Button
      variant="contained"
      color="error"
      startIcon={<UnpublishedIcon />}
      size="small"
      onClick={handleToggleIsTest}
      disabled={isLoading}
    >
      TEST
    </Button>
  ) : (
    <Button
      variant="contained"
      color="success"
      startIcon={<CheckCircleIcon />}
      size="small"
      onClick={handleToggleIsTest}
      disabled={isLoading}
    >
      TEST
    </Button>
  );
};
