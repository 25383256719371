import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
  isSupervisor?: boolean;
  isSupervisorUrl: string;
}

export const useToggleIsSupervisor = ({ isSupervisor, isSupervisorUrl, id }: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
    isSupervisorUrl,
    {
      id,
      data: { isSupervisor: !isSupervisor },
    },
    {
      onSettled: () => {
        refresh();
      },
    },
  );

  const handleToggleIsSupervisor = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    toggle,
    handleToggleIsSupervisor,
    isLoading,
    error,
  };
};
