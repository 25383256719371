import { Datagrid, List, TextField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { TherapyMethodsListActions } from './ui/actions';

export const TherapyMethodsList = () => {
  return (
    <List actions={<TherapyMethodsListActions />} pagination={false}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="name" label={LABELS.name} />
      </Datagrid>
    </List>
  );
};
