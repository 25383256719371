import {
  Create,
  Loading,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { API_URL } from 'shared/constants/api-url';
import { useHttpClient } from 'shared/hooks/useHttpClient';

export const DiseasesCreate = () => {
  const { data, isLoading } = useHttpClient(API_URL.diseasesGroup);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const diseasesGroupChoices = data.data.map(({ id, name }: any) => ({
    id,
    name,
  }));

  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" fullWidth validate={[required()]} />
        <TextInput source="description" fullWidth />
        <SelectInput
          source="diseasesGroupId"
          choices={diseasesGroupChoices}
          fullWidth
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
