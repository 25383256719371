import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Button } from '@mui/material';
import { useToggleIsAdult } from 'features/toggle-is-adult/model';
import { FC } from 'react';

interface Props {
  id?: string;
  isAdult?: boolean;
  isAdultUrl: string;
}

export const ToggleIsAdult: FC<Props> = ({ isAdult, isAdultUrl, id }) => {
  const { handleToggleIsAdult, isLoading } = useToggleIsAdult({
    isAdult,
    id,
    isAdultUrl,
  });

  return !isAdult ? (
    <Button
      variant="contained"
      color="error"
      startIcon={<UnpublishedIcon />}
      size="small"
      onClick={handleToggleIsAdult}
      disabled={isLoading}
    >
      ADULT
    </Button>
  ) : (
    <Button
      variant="contained"
      color="success"
      startIcon={<CheckCircleIcon />}
      size="small"
      onClick={handleToggleIsAdult}
      disabled={isLoading}
    >
      ADULT
    </Button>
  );
};
