import styled from 'styled-components';
import { LoginByEmail } from 'features/auth/by-email';
import LoginBackground from 'shared/images/client-auth-background.svg';

export const SignInPage = () => {
  return (
    <Root>
      <LoginByEmail />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url(${LoginBackground}) no-repeat center;
  background-size: cover;
`;
