import { Loading, Show, useGetOne, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { TherapistsRequestsShowActions } from './ui/actions';
import { TherapistsRequestsShowContent } from './ui/content';

export const TherapistsRequestsShow = () => {
  const { id } = useParams();

  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    'therapists/requests',
    { id: id as string },
    { onError: () => redirect('/therapists/requests') },
  );
  if (isLoading) {
    return <Loading />;
  }
  const pageTitle = `${data?.personalInfo.firstname} ${data?.personalInfo.lastname}`;

  return (
    <Show title={pageTitle} actions={<TherapistsRequestsShowActions />}>
      <TherapistsRequestsShowContent data={data} />
    </Show>
  );
};
