import * as therapists from './therapists';
import * as promocode from './promocode';
import * as sessions from './sessions';

const backend = {
  
  therapists,
  promocode,
  sessions

}

export default backend;