import {
  Edit,
  Form,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { httpClient } from 'shared/services/http-client';
import { uploadFile } from 'shared/utils/upload-file';

export const CompaniesEdit = () => {
  const { id } = useParams();
  const notify = useNotify();

  const handleSubmit = async ({ name, avatarKey }: any) => {
    if (!id) return;

    let newAvatarKey = undefined;

    if (avatarKey?.rawFile) {
      const uploadedFile = await uploadFile(avatarKey?.rawFile);
      newAvatarKey = uploadedFile.key;
    }

    try {
      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.editCompany(id)}`, {
        method: 'PATCH',
        body: JSON.stringify({ name, avatarKey: newAvatarKey }),
      });

      notify('Данные о компании обновлены', { type: 'success' });
    } catch (error) {
      notify('Что-то пошло не так...', { type: 'error' });
    }
  };

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput label={LABELS.companyName} source="name" fullWidth />
        <ImageField
          label="Текущая фотография"
          source="owner.avatarKey"
          sx={{
            '& .RaImageField-image': {
              width: 300,
              height: 300,
              objectFit: 'cover',
            },
          }}
        />
        <ImageInput source="avatarKey" label={LABELS.avatarKey}>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
