import Papa from 'papaparse';

export const convertObjArrayToCsv = (template: Array<Record<string, any>>) => {
  const columns = Object.keys(template[0]);
  const data = template.map((item) => Object.values(item));

  const formattedData = {
    fields: columns,
    data,
  };

  return Papa.unparse(formattedData, {
    quoteChar: '',
    escapeChar: '',
  });
};
