import { Button } from '@mui/material';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { FC } from 'react';
import { useCancelSessionPayment } from './model';

interface Props {
  sessionId: string;
  onSuccess?: () => void;
}

export const CancelSessionPayment: FC<Props> = ({ sessionId, onSuccess }) => {
  const [cancelSessionPayment, { isLoading }] = useCancelSessionPayment({
    sessionId,
    onSuccess,
  });

  return (
    <Button
      variant="outlined"
      color="error"
      startIcon={<UnpublishedIcon />}
      size="small"
      disabled={isLoading}
      onClick={() => cancelSessionPayment()}
    >
      Отменить платеж
    </Button>
  );
};
