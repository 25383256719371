import { useEffect, useState } from 'react';
import { DateTimeInput, Form, SaveButton } from 'react-admin';
import { SessionStatisticsResponseDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import styled from 'styled-components';

const now = new Date();
const defaultStartDate = new Date(
  now.getFullYear(),
  now.getMonth() - 1,
).toISOString();
const defaultEndDate = new Date().toISOString();

const PromotedSessionStatistics = () => {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [data, setData] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);
  const {
    isLoading: isLoadingStatisticsSessions,
    data: dataStatisticsSessions,
  } = useHttpClient<SessionStatisticsResponseDto>(
    `${API_URL.statisticsSessions}?startDate=${startDate}&endDate=${endDate}`,
  );

  useEffect(() => {
    const token = localStorage.getItem('token');

    setIsLoading(true);
    fetch(
      `${API_BASE_URL_ADMIN}${API_URL.promotedSessionStatistics}?startDate=${startDate}&endDate=${endDate}&isPromoted=true`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      },
    )
      .then((res) => res.blob())
      .then((result) => setData(result))
      .finally(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate]);

  if (isLoadingStatisticsSessions || !dataStatisticsSessions) return null;

  const download = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);

    const tempAnchor = document.createElement('a');
    tempAnchor.setAttribute('href', url);
    tempAnchor.setAttribute('download', 'statistics_promoted_sessions.xls');
    tempAnchor.click();
    tempAnchor.remove();
  };

  const handleSubmit = ({ startDate, endDate }: any) => {
    if (startDate && typeof startDate !== 'string') {
      setStartDate(startDate?.toISOString());
    }

    if (endDate && typeof endDate !== 'string') {
      setEndDate(endDate?.toISOString());
    }
  };

  return (
    <>
      <Root>
        {isLoading && <p>Загрузка...</p>}
        <Form onSubmit={handleSubmit}>
          <div>
            <DateTimeInput
              label="Начало промежутка"
              source="startDate"
              style={{ marginRight: '10px' }}
              defaultValue={startDate}
            />
            <DateTimeInput
              label="Конец промежутка"
              source="endDate"
              defaultValue={endDate}
              style={{ marginRight: '10px' }}
            />
          </div>
          <SaveButton label="Изменить" disabled={isLoading} />
        </Form>
        <DownloadBtn
          disabled={!data || isLoading}
          onClick={() => data && download(data)}
        >
          Скачать
        </DownloadBtn>
        <p>{`Количество завершенных сессий: ${dataStatisticsSessions?.completed}`}</p>
        <p>{`Количество отмененных сессий: ${dataStatisticsSessions?.canceled}`}</p>
        <p>{`Количество забронированных сессий: ${dataStatisticsSessions?.booked}`}</p>
      </Root>
    </>
  );
};

export default PromotedSessionStatistics;

const Root = styled.div`
  padding: 20px;
`;

const DownloadBtn = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;
