import { useEffect, useState } from 'react';
import { DateTimeInput, Form, SaveButton, SelectArrayInput } from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { VARS } from 'shared/constants/vars';
import styled from 'styled-components';

const now = new Date();
const defaultStartDate = new Date(
  now.getFullYear(),
  now.getMonth() - 1,
).toISOString();
const defaultEndDate = new Date().toISOString();

export type PayoutStatus =
  | 'awaiting'
  | 'pending'
  | 'awaiting_promocode'
  | 'succeeded'
  | 'canceled';

export const PayoutStatusMapRU: Record<PayoutStatus, string> = {
  awaiting: 'Ожидает оплаты клиента',
  awaiting_promocode: 'Ожидает оплаты по промокоду',
  canceled: 'Отменена',
  pending: 'Перевод денег клиента терапевту',
  succeeded: 'Выплачено',
};

const PromocodesStatistics = () => {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [data, setData] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);
  const [payoutStatuses, setPayoutStatuses] = useState<string[]>();

  useEffect(() => {
    const token = localStorage.getItem('token');

    setIsLoading(true);
    fetch(
      `${API_BASE_URL_ADMIN}${
        API_URL.promotedSessionStatistics
      }?startDate=${startDate}&endDate=${endDate}&isHavePromoCode=true${
        payoutStatuses
          ? `&payoutStatuses=${payoutStatuses?.join('&payoutStatuses=')}`
          : ''
      }`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      },
    )
      .then((res) => res.blob())
      .then((result) => setData(result))
      .finally(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate, payoutStatuses]);

  const download = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);

    const tempAnchor = document.createElement('a');
    tempAnchor.setAttribute('href', url);
    tempAnchor.setAttribute('download', 'statistics_promocodes.xls');
    tempAnchor.click();
    tempAnchor.remove();
  };

  const handleSubmit = ({ startDate, endDate, payoutStatuses }: any) => {
    if (startDate && typeof startDate !== 'string')
      setStartDate(startDate?.toISOString());
    if (endDate && typeof endDate !== 'string')
      setEndDate(endDate?.toISOString());
    if (payoutStatuses?.length) {
      const payoutStatusesNames = (payoutStatuses as number[]).map((item) => {
        const requiredItem = VARS.payoutStatusesChoices.find(
          (choice) => choice.id === item,
        );

        return requiredItem!.name;
      });

      setPayoutStatuses(payoutStatusesNames);
    } else setPayoutStatuses(undefined);
  };

  return (
    <>
      <Root>
        {isLoading && <p>Загрузка...</p>}
        <Form onSubmit={handleSubmit}>
          <div>
            <DateTimeInput
              label="Начало промежутка"
              source="startDate"
              style={{ marginRight: '10px' }}
              defaultValue={startDate}
            />
            <DateTimeInput
              label="Конец промежутка"
              source="endDate"
              defaultValue={endDate}
              style={{ marginRight: '10px' }}
            />
            <SelectArrayInput
              label="Статус"
              source="payoutStatuses"
              choices={VARS.payoutStatusesChoices.map((item) => ({
                ...item,
                name: PayoutStatusMapRU[item.name as PayoutStatus],
              }))}
            />
          </div>
          <SaveButton label="Изменить" disabled={isLoading} />
        </Form>
        <DownloadBtn
          disabled={!data || isLoading}
          onClick={() => data && download(data)}
        >
          Скачать
        </DownloadBtn>
      </Root>
    </>
  );
};

export default PromocodesStatistics;

const Root = styled.div`
  padding: 20px;
`;

const DownloadBtn = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;
