import { ToggleBan } from 'features/toggle-ban';
import { ToggleIsTest } from 'features/toggle-is-test';
import { FC } from 'react';
import {
  DateField,
  EmailField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  WrapperField,
} from 'react-admin';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { PatientsShowActions } from '../actions';
import { Button } from '@mui/material';

interface Props {

  id?: string;
  isTest: boolean;
  isCard: boolean;
  isBanned: boolean;
  avatarKey?: string;
  onBookSession: () => void;

}

export const PatientInfo: FC<Props> = ({ id, isBanned, isTest, isCard, avatarKey, onBookSession }) => {

  return (
    <Show
      actions={
        <PatientsShowActions
          extra={[
            <Button
              onClick={onBookSession}
              variant="contained"
              color="success"
              size="small"
              disabled={!isCard}
            >
              Забронировать сессию
            </Button>,
            <ToggleIsTest
              isTest={ isTest }
              id={id}
              isTestUrl={API_URL.patientIsTest}
            />,
            <ToggleBan
              id={id}
              isBan={isBanned}
              banUrl={API_URL.patientBan}
              unbanUrl={API_URL.patientUnban}
            />,
          ]}
        />
      }
    >

      <SimpleShowLayout>
        <TextField source="firstname" label={LABELS.firstname} />
        <TextField source="lastname" label={LABELS.lastname} />
        <DateField source="dateOfBirth" label={LABELS.dateOfBirth} />
        <TextField source="gender" label={LABELS.gender} />
        <EmailField source="email" label={LABELS.email} />
        <TextField source="phone" label={LABELS.phone} />
        <TextField source="country" label={LABELS.country} />
        <TextField source="city" label={LABELS.city} />
        <WrapperField label={LABELS.avatarKey}>
          <a rel="noreferrer" href={avatarKey} target="_blank">
            <ImageField
              source="avatarKey"
              sx={{
                '& .RaImageField-image': {
                  width: 300,
                  height: 300,
                  objectFit: 'cover',
                },
              }}
            />
          </a>
        </WrapperField>
        <TextField source="utmCampaign" label={LABELS.utmCampaign} />
        <TextField source="utmMedium" label={LABELS.utmMedium} />
        <TextField source="utmSource" label={LABELS.utmSource} />
      </SimpleShowLayout>
    </Show>
  );
};
