import { FC, useState } from 'react';
import { DateTimeInput, Form, SaveButton } from 'react-admin';
import { SessionStatisticsResponseDto } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import styled from 'styled-components';

interface Props {
  therapistId: string;
}

const defaultStartDate = new Date().toISOString();
const defaultEndDate = new Date().toISOString();

const Statistics: FC<Props> = ({ therapistId }) => {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const { isLoading, data } = useHttpClient<SessionStatisticsResponseDto>(
    `${API_URL.statisticsTherapistSessions(
      therapistId,
    )}?startDate=${startDate}&endDate=${endDate}`,
  );

  if (!data || isLoading) return null;

  const handleSubmit = ({ startDate, endDate }: any) => {
    if (startDate && typeof startDate !== 'string')
      setStartDate(startDate?.toISOString());
    if (endDate && typeof endDate !== 'string')
      setEndDate(endDate?.toISOString());
  };

  return (
    <Root>
      <Form onSubmit={handleSubmit}>
        <div>
          <DateTimeInput
            label="Начало промежутка"
            source="startDate"
            style={{ marginRight: '10px' }}
            defaultValue={startDate}
          />
          <DateTimeInput
            label="Конец промежутка"
            source="endDate"
            defaultValue={endDate}
          />
        </div>
        <SaveButton disabled={isLoading} />
      </Form>
      <p>{`Количество завершенных сессий: ${data?.completed}`}</p>
      <p>{`Количество отмененных сессий: ${data?.canceled}`}</p>
      <p>{`Количество забронированных сессий: ${data?.booked}`}</p>
    </Root>
  );
};

export default Statistics;

const Root = styled.div`
  padding: 20px;
`;
