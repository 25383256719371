import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
  isMedsi?: boolean;
  isMedsiUrl: string;
}

export const useToggleIsMedsi = ({ isMedsi, isMedsiUrl, id }: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
    isMedsiUrl,
    {
      id,
      data: { isMedsi: !isMedsi },
    },
    {
      onSettled: () => {
        refresh();
      },
    },
  );

  const handleToggleIsMedsi = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    toggle,
    handleToggleIsMedsi,
    isLoading,
    error,
  };
};
