import { TopToolbar } from 'react-admin';
import { FC, ReactNode } from 'react';

interface Props {
  extra: ReactNode[];
}

export const TherapistShowActions: FC<Props> = (props) => {
  return <TopToolbar>{props.extra.map((item) => item)}</TopToolbar>;
};
