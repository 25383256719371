import { ToggleApprove } from 'features/toggle-approve';
import { ToggleBan } from 'features/toggle-ban';
import { ToggleIsMedsi } from 'features/toggle-is-medsi';
import { ToggleIsTest } from 'features/toggle-is-test';
import { ToggleIsAdult } from 'features/toggle-is-adult';
import { ToggleIsTop } from 'features/toggle-is-top';
import { Loading, Show, useGetOne, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { AdminTherapistResponseDto } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';
import { TherapistShowActions } from './ui/actions';
import { TherapistShowContent } from './ui/content';
import { ToggleIsChild } from 'features/toggle-is-child';
import { ToggleIsSupervisor } from 'features/toggle-is-supervisor';

export const TherapistsShow = () => {
  const { id } = useParams();

  const redirect = useRedirect();
  const { data, isLoading } = useGetOne<AdminTherapistResponseDto>(
    'therapists',
    { id: id as string },
    { onError: () => redirect('/therapists') },
  );
  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;
  const pageTitle = `${data?.personalInfo.firstname} ${data?.personalInfo.lastname}`;

  return (
    <Show
      title={pageTitle}
      actions={
        <TherapistShowActions
          extra={[
            <ToggleIsTest
              isTest={data?.isTest}
              id={id}
              isTestUrl={API_URL.therapistIsTest}
            />,
            <ToggleIsAdult
              isAdult={data?.isAdult}
              id={id}
              isAdultUrl={API_URL.therapistIsAdult}
            />,
            <ToggleIsChild
              isChild={data?.isChildTherapist}
              id={id}
              isChildUrl={API_URL.therapistIsChild}
            />,
            <ToggleIsSupervisor
              isSupervisor={data?.isSupervisor}
              id={id}
              isSupervisorUrl={API_URL.therapistIsSupervisor}
            />,
            <ToggleIsMedsi
              isMedsi={data?.isMedsi}
              id={id}
              isMedsiUrl={API_URL.therapistIsMedsi}
            />,
            <ToggleIsTop
              isTop={data?.isTop}
              id={id}
              isTopUrl={API_URL.therapistIsTop}
            />,
            <ToggleApprove
              isApproved={data?.isApproved}
              id={id}
              approveUrl={API_URL.therapistApprove}
              unapproveUrl={API_URL.therapistUnapprove}
            />,
            <ToggleBan
              isBan={!!data?.deletedAt}
              id={id}
              banUrl={API_URL.therapistBan}
              unbanUrl={API_URL.therapistUnban}
            />,
          ]}
        />
      }
    >
      <TherapistShowContent data={data} />
    </Show>
  );
};
