import { useNotify, useDelete } from 'react-admin';
import { PaymentResponseDto } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';

export interface UseCancelSessionPaymentArguments {
  sessionId: string;
  onSuccess?: () => void;
}

export const useCancelSessionPayment = ({
  sessionId,
  onSuccess,
}: UseCancelSessionPaymentArguments) => {
  const notify = useNotify();

  return useDelete<PaymentResponseDto, any>(
    API_URL.cancelSessionPayment,
    { id: sessionId },
    {
      onSuccess: () => {
        notify('Платеж успешно отменен', { type: 'success' });
        onSuccess?.();
      },
      onError: (error) => {
        notify(error.message || 'Что-то пошло не так...', {
          type: 'error',
        });
      },
    },
  );
};
