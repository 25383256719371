import { isArray, isEqual, isObject, transform } from 'lodash';

export function differenceBetweenObjects(
  origObj: Record<string, any>,
  newObj: Record<string, any>,
): Record<string, any> {
  function changes(newObj: Record<string, any>, origObj: Record<string, any>) {
    let arrayIndexCounter = 0;
    return transform(newObj, function (result: any, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  }
  return changes(newObj, origObj);
}
