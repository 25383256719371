import { ToggleArchiveCompany } from 'features/toggle-archive-company';
import { TopToolbar, useShowContext } from 'react-admin';

export const CompaniesShowActions = () => {
  const { record } = useShowContext();

  return (
    <TopToolbar>
      <ToggleArchiveCompany {...record} />
    </TopToolbar>
  );
};
