import { TherapistWorkingInfoResponseDtoWorkWithReligionsEnum } from 'shared/api';
import { VARS } from 'shared/constants/vars';

export const getReligions = (
  religions: TherapistWorkingInfoResponseDtoWorkWithReligionsEnum[]
) => {
  return religions
    .map((item) => VARS.russianTranslateReligions[item])
    .join(', ');
};
