import { SearchStatisticsResponseDto, TherapistWorkingInfoResponseDtoWorkWithReligionsEnum } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';
import { VARS } from 'shared/constants/vars';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import styled from 'styled-components';

const SearchStatistics = () => {
  const {
    isLoading,
    data,
  } = useHttpClient<SearchStatisticsResponseDto>(
    `${API_URL.searchStatistics}`,
  );

  if (!data || isLoading) return null;

  return (
    <Root>
       {data.price && <div>
        <b>Стоимость сессии:</b>
        <p>25-30 т.р.: {data.price.lesser30k}%</p>
        <p>20-25 т.р.: {data.price.lesser25k}%</p>
        <p>15-20 т.р.: {data.price.lesser20k}%</p>
        <p>10-15 т.р.: {data.price.lesser15k}%</p>
        <p>5-10 т.р.: {data.price.lesser10k}%</p>
        <p>до 5 т.р.: {data.price.lesser5k}%</p>
      </div>}
      {data.dayLimits && <div>
        <b>Дней до сессии:</b>
        <p>1 день до сессии: {data.dayLimits.dayLimit1}</p>
        <p>3 дня до сессии: {data.dayLimits.dayLimit3}</p>
        <p>7 дней до сессии: {data.dayLimits.dayLimit7}</p>
        <p>30 дней до сессии: {data.dayLimits.dayLimit30}</p>
      </div>}
      {data.genders && <div>
        <b>Пол терапевта:</b>
        <p>Мужской: {data.genders.male}%</p>
        <p>Женский: {data.genders.female}%</p>
      </div>}
      {!!data.diseases?.length && <div>
        <b>Запросы</b>
        {data.diseases.map(diseases => <p>{diseases.name}: {diseases.percentage}%</p>)}
      </div>}
      {!!data.therapyMethods?.length && <div>
        <b>Методы терапии</b>
        {data.therapyMethods.map(therapyMethod => <p>{therapyMethod.name}: {therapyMethod.percentage}%</p>)}
      </div>}
      {!!data.religions?.length && <div>
        <b>Работает с религиями</b>
        {/* @ts-ignore */}
        {data.religions.map(religion => <p>{VARS.russianTranslateReligions[religion.name]}: {religion.percentage}%</p>)}
      </div>}
      <p><b>Работает с ЛГБТ:</b> {data.workWithLgbt}%</p>
      <p><b>Работает с этническими группами:</b> {data.workWithEthnicGroups}%</p>
    </Root>
  );
};

export default SearchStatistics;

const Root = styled.div`
  padding: 20px;
`;