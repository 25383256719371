import { CreateButton, ExportButton, TopToolbar } from 'react-admin';
import XLSX from 'xlsx';

export const TherapyMethodsListActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ExportButton exporter={(...args) => {
        const worksheet = XLSX.utils.json_to_sheet(args[0], { sheetStubs: true });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

        worksheet["!cols"] = new Array(50).fill({ width: 40 });
        XLSX.writeFile(workbook, "Therapists.xlsx", { compression: true });
      }} />
    </TopToolbar>
  );
};
