import { FC } from 'react';
import { Button } from '@mui/material';
import { useToggleApprove } from './model';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

interface Props {
  id?: string;
  isApproved?: boolean;
  approveUrl: string;
  unapproveUrl: string;
}

export const ToggleApprove: FC<Props> = ({
  isApproved,
  approveUrl,
  unapproveUrl,
  id,
}) => {
  const { handleToggleApprove, isLoading } = useToggleApprove({
    id,
    isApproved,
    approveUrl,
    unapproveUrl,
  });

  return isApproved ? (
    <Button
      variant="contained"
      color="error"
      startIcon={<UnpublishedIcon />}
      size="small"
      onClick={handleToggleApprove}
      disabled={isLoading}
    >
      Unapprove
    </Button>
  ) : (
    <Button
      variant="contained"
      color="success"
      startIcon={<CheckCircleIcon />}
      size="small"
      onClick={handleToggleApprove}
      disabled={isLoading}
    >
      Approve
    </Button>
  );
};
