import { useState } from 'react';
import { useNotify } from 'react-admin';
import { AddPromocodesToCompany } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';

interface Arguments {
  companyId: string;
}

export const useAddPromocodes = ({ companyId }: Arguments) => {
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();

  const url = API_URL.addPromocodes(companyId);

  const addPromocodes = (data: AddPromocodesToCompany) => {
    setIsLoading(true);
    httpClient(`${API_BASE_URL_ADMIN}${url}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        notify('Промокоды добавлены', { type: 'success' });
      })
      .catch((error) => {
        notify(error.message || 'Что-то пошло не так...', { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { addPromocodes, isLoading };
};
