import { AuthProvider } from 'react-admin';
import {
  AdminResponseDtoRoleEnum,
  AdminWithTokenResponseDto,
} from 'shared/api';
import { API_BASE_URL } from 'shared/config';
import { ResourseNames } from 'shared/types';

type AdminData = {
  token: string;
  userId: string;
  userName: string;
  userRole: string;
};

enum PermissionActions {
  Read = 'read',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
}

export type PermissionType = {
  resource: '*' | ResourseNames;
  action: '*' | PermissionActions | PermissionActions[];
};

const setAdminData = ({ token, userId, userName, userRole }: AdminData) => {
  localStorage.setItem('token', token);
  localStorage.setItem('userId', userId);
  localStorage.setItem('userName', userName);
  localStorage.setItem('userRole', userRole);
};

const getAdminData = () => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('userName');
  const userRole = localStorage.getItem('userRole') as AdminResponseDtoRoleEnum;

  return { token, userId, userName, userRole };
};

const removeAdminData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('userName');
  localStorage.removeItem('userRole');
};

const PERMISSIONS: Record<AdminResponseDtoRoleEnum, PermissionType[]> = {
  [AdminResponseDtoRoleEnum.Superadmin]: [{ resource: '*', action: '*' }],
  [AdminResponseDtoRoleEnum.Admin]: [{ resource: '*', action: '*' }],
  [AdminResponseDtoRoleEnum.Askona]: [
    {
      resource: ResourseNames.patients,
      action: [PermissionActions.Read, PermissionActions.Export],
    },
    {
      resource: ResourseNames.statisticsPromotedSessions,
      action: [PermissionActions.Read, PermissionActions.Export],
    },
  ],
  [AdminResponseDtoRoleEnum.Medsi]: [
    {
      resource: ResourseNames.patients,
      action: [PermissionActions.Read, PermissionActions.Export],
    },
    {
      resource: ResourseNames.statisticsPromotedSessions,
      action: [PermissionActions.Read, PermissionActions.Export],
    },
    {
      resource: ResourseNames.apiKeys,
      action: '*',
    },
    {
      resource: ResourseNames.webhooks,
      action: '*',
    },
  ],
};

const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    const request = new Request(`${API_BASE_URL}/auth/admins/sign-in`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(async (response) => {
        const result = await response.json();

        if (response.status < 200 || response.status >= 300) {
          throw new Error(result.message);
        }

        return result;
      })
      .then((data: AdminWithTokenResponseDto) => {
        setAdminData({
          token: data.accessToken,
          userId: data.admin.id,
          userName: data.admin.name,
          userRole: data.admin.role,
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  logout: () => {
    removeAdminData();
    return Promise.resolve();
  },
  checkAuth: () => {
    const { token } = getAdminData();
    return token ? Promise.resolve() : Promise.reject();
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      removeAdminData();
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    const { userId, userName, userRole } = getAdminData();

    if (userId && userName && userRole) {
      return Promise.resolve({
        id: userId,
        fullName: userName,
        role: userRole,
      });
    }

    return Promise.reject();
  },
  getPermissions: () => {
    const { userRole } = getAdminData();

    return userRole ? Promise.resolve(PERMISSIONS[userRole]) : Promise.reject();
  },
};

export default authProvider;
