import { parse, stringify } from 'query-string';
import { isValidSortingValue } from './is-valid-sorting-value';

export const replaceQueryParams = (searshQuery: string) => {
  const parsedQuery = parse(searshQuery);

  const queryKeys = Object.keys(parsedQuery);

  const sortKey = queryKeys.find((item) => item.includes('sort'));

  if (sortKey) {
    const sortValues = (parsedQuery[sortKey] as string).split(',');

    if (isValidSortingValue(sortValues[0])) {
      parsedQuery.order = sortValues[1];
      parsedQuery.orderTerms = sortValues[0];
    }

    delete parsedQuery[sortKey];
    delete parsedQuery['offset'];
  }

  return stringify(parsedQuery);
};
