import { Edit, SimpleForm, TextInput } from 'react-admin';
import { LABELS } from 'shared/constants/labels';

export const DiseasesGroupEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" fullWidth label={LABELS.name} />
      </SimpleForm>
    </Edit>
  );
};
