import { FC, ReactNode } from 'react';
import { TopToolbar } from 'react-admin';

interface Props {
  extra: ReactNode[];
}

export const PatientsShowActions: FC<Props> = (props) => {
  return <TopToolbar>{props.extra.map((item) => item)}</TopToolbar>;
};
