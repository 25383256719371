import { TherapistWorkingInfoResponseDtoLanguagesEnum } from 'shared/api';
import { VARS } from 'shared/constants/vars';

export const getLanguages = (
  languages: TherapistWorkingInfoResponseDtoLanguagesEnum[]
) => {
  return languages
    .map((item) => VARS.russianTranslateLanguage[item])
    .join(', ');
};
