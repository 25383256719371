import { Button, Typography } from "@mui/material"
import { Show, SimpleShowLayout } from "react-admin"
import React, { FC } from 'react';
import styled from "styled-components";
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
interface Props {

  onBack: () => void;
  patientFirstname?: string;
  patientLastname?: string;
  patientPhone?: string; 
  patientEmail?: string; 
  therapistFirstname?: string; 
  selectedTherapist?: {
    name: string;
    price: number;
  };
  date: string;
  promoCode: string;
  selectedTime: string;
  finalPrice: number;
  isPromoCodeValid: boolean;
  
}


export const PatientSessionBooked: FC<Props> = (props) => {

  const {

    onBack, 
    patientFirstname, 
    patientLastname, 
    patientPhone, 
    patientEmail,
    selectedTherapist,
    date,
    selectedTime,
    promoCode,
    isPromoCodeValid,
    finalPrice

  } = props;

  const sessionPrice = ( selectedTherapist?.price || 0 );
  const fixedDiscount = sessionPrice - finalPrice;
  const discountPercent = ( fixedDiscount / sessionPrice ) * 100;
  const formattedDate = format( new Date( date ), 'd MMMM yyyy,', { locale: ru } );

  return (

    <Show>

      <SimpleShowLayout>
        
        <BlocksWrapper>

          <TitleBooked>Сессия забронирована</TitleBooked>

        </BlocksWrapper>

        <BlocksWrapper>

          <TitleBooked>Клиент</TitleBooked>

          <div>

            Имя:{' '}
            
            {( patientFirstname || patientLastname )

            ? `${ patientFirstname || "" } ${ patientLastname || "" }` 
            : "Отстутвует" 

            }

          </div> 

          <div>Телефон: { patientPhone || "Отстутвует" }</div> 
          <div>Почта: { patientEmail || "Отстутвует" }</div>

        </BlocksWrapper>

        <BlocksWrapper>

          <TitleBooked>Терапевт</TitleBooked>

            <div>Имя: {selectedTherapist?.name || "Отсутствует"}</div>
          

        </BlocksWrapper>  

        <BlocksWrapper>

          <TitleBooked>
            
            Стоимость { isPromoCodeValid ? '(без учёта промокода)' : '' }

          </TitleBooked>

          <div>Стоимость: {selectedTherapist?.price || "Отсутствует"} ₽</div>

        </BlocksWrapper> 

        { isPromoCodeValid && (
       
          <>

            <BlocksWrapper>

              <TitleBooked>Промокод</TitleBooked>
              <div>{ promoCode } | { discountPercent }%</div>

            </BlocksWrapper>

            <BlocksWrapper>

              <TitleBooked>Стоимость (с учётом промокода)</TitleBooked>
              <div>{finalPrice} ₽</div>

            </BlocksWrapper> 

          </>

        )}

        <BlocksWrapper>

          <div>Дата сессии</div>
          <div>{ formattedDate } { selectedTime }</div>

        </BlocksWrapper> 

        <Button

          onClick = { onBack }
          variant = "contained"
          color = "success"
          size = "small"

        >

          Вернуться

        </Button>

      </SimpleShowLayout>

    </Show>

  );

}

const TitleBooked = styled(Typography)`

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

`;

const BlocksWrapper = styled(Typography)`

  margin-bottom: 14px;

`;