import * as React from 'react';
import './styles/common.css';
import { Admin, Layout } from 'react-admin';
import crudProvider from '@fusionworks/ra-data-nest-crud';
import { API_BASE_URL_ADMIN } from 'shared/config';
import authProvider from './providers/auth-provider';
import { httpClient } from 'shared/services/http-client';
import { SidebarMenu } from 'widgets/sidebar-menu';
import { SignInPage } from 'pages/auth/sign-in';
import { getResources } from './resources';

const dataProvider = crudProvider(API_BASE_URL_ADMIN, httpClient);

const App = () => (
  <Admin
    loginPage={SignInPage}
    dataProvider={dataProvider}
    authProvider={authProvider}
    layout={(props) => <Layout {...props} menu={SidebarMenu} />}
  >
    {(permissions) => getResources(permissions)}
  </Admin>
);

export default App;
