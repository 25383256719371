import { useRecordContext } from 'react-admin';
import { AdminSessionResponseDto } from 'shared/api';
import { CancelSessionPayment } from 'features/cancel-session-payment';
import { SetSessionPaymentPaid, SetSessionPromocodePaid } from 'features/set-session-payment-paid';
import { FC } from 'react';

interface Props {
  refetch?: () => void;
}

const RowActions: FC<Props> = ({ refetch }) => {
  const record = useRecordContext<AdminSessionResponseDto>();

  return (
    <>
      {(record.payment.status as unknown) === 'waiting_for_capture' && (
        <CancelSessionPayment sessionId={record.id} onSuccess={refetch} />
      )}
      {record.payment.discountValue > 0 && !record.payment.isPromocodePaid && (
        <SetSessionPromocodePaid sessionId={record.id} onSuccess={refetch} />
      )}
      {record.payment.status === 'succeeded' &&  record.payment.payoutStatus === 'awaiting' && record.payment.payoutTerminal === 'manual' && (
        <SetSessionPaymentPaid sessionId={record.id} onSuccess={refetch} />
      )}
    </>
  );
};
export default RowActions;
