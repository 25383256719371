import { Datagrid, List, TextField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';

export const FaqGroupList = () => {
  return (
    <List pagination={false}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="title" label={LABELS.title} sortable={false} />
      </Datagrid>
    </List>
  );
};
