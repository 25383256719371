import { Create, required, SimpleForm, TextInput } from 'react-admin';

export const DiseasesGroupCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" fullWidth validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
