import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FC } from 'react';
import { useSetSessionPaymentPaid, useSetSessionPromocodePaid } from './model';

interface Props {
  sessionId: string;
  onSuccess?: () => void;
}

export const SetSessionPromocodePaid: FC<Props> = ({ sessionId, onSuccess }) => {
  const [setSessionPaymentPaid, { isLoading }] = useSetSessionPromocodePaid({
    sessionId,
    onSuccess,
  });

  return (
    <Button
      variant="outlined"
      color="success"
      size="small"
      startIcon={<CheckCircleIcon />}
      onClick={() => setSessionPaymentPaid()}
      disabled={isLoading}
    >
      Отметить промокод выплаченным
    </Button>
  );
};

export const SetSessionPaymentPaid: FC<Props> = ({ sessionId, onSuccess }) => {
  const [setSessionPaymentPaid, { isLoading }] = useSetSessionPaymentPaid({
    sessionId,
    onSuccess,
  });

  return (
    <Button
      variant="outlined"
      color="success"
      size="small"
      startIcon={<CheckCircleIcon />}
      onClick={() => setSessionPaymentPaid()}
      disabled={isLoading}
    >
      Отметить консультацию выплаченной
    </Button>
  );
};

