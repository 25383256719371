import { Create, required, SimpleForm, TextInput } from 'react-admin';

export const PatientCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="title" validate={[required()]} fullWidth />
        <TextInput source="teaser" multiline={true} label="Short description" />
      </SimpleForm>
    </Create>
  );
};
