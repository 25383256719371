const TherapistWorkingInfoResponseDtoWorkApproachRu: any = {
  guiding: 'Направляющий',
  supporting: 'Поддерживающий',
  soft: 'Мягкий',
  straight: 'Прямой',
  informal: 'Неформальный',
  serious: 'Серьезный',
}

export const getWorkApproach = ( workApproach?: string ) => {
  if ( !workApproach ) return '';
  
 const ruWorkApproach = TherapistWorkingInfoResponseDtoWorkApproachRu?.[ workApproach ] || workApproach
 return ruWorkApproach;
};
