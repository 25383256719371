import { Menu, MenuItemLinkProps, usePermissions } from 'react-admin';
import PsychologyIcon from '@mui/icons-material/Psychology';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import SpaIcon from '@mui/icons-material/Spa';
import FolderIcon from '@mui/icons-material/Folder';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { ResourseNames } from 'shared/types';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ApiIcon from '@mui/icons-material/Api';
import WebhookIcon from '@mui/icons-material/Webhook';
import { PermissionType } from 'app/providers/auth-provider';

type MenuItem = MenuItemLinkProps & {
  resourseName: ResourseNames;
};

const MENU_ITEMS: MenuItem[] = [
  {
    resourseName: ResourseNames.therapists,
    to: '/therapists',
    primaryText: 'Терапевты',
    leftIcon: <PsychologyIcon />,
  },
  {
    resourseName: ResourseNames.therapistsRequests,
    to: '/therapists/requests',
    primaryText: (
      <div>
        Запросы на создание <br /> профиля терапевта
      </div>
    ),
    leftIcon: <PsychologyIcon />,
  },
  {
    resourseName: ResourseNames.therapistsProfileUpdates,
    to: '/therapists/profile-updates',
    primaryText: (
      <div>
        Запросы на обновление <br /> профиля терапевта
      </div>
    ),
    leftIcon: <PsychologyIcon />,
  },
  {
    resourseName: ResourseNames.companies,
    to: '/b2b/companies',
    primaryText: 'Компании',
    leftIcon: <BusinessIcon />,
  },
  {
    resourseName: ResourseNames.companiesRequests,
    to: '/b2b/requests',
    primaryText: (
      <div>
        Запросы на создание <br /> профиля компаний
      </div>
    ),
    leftIcon: <BusinessIcon />,
  },
  {
    resourseName: ResourseNames.patients,
    to: '/patients',
    primaryText: 'Клиенты',
    leftIcon: <GroupIcon />,
  },
  {
    resourseName: ResourseNames.statisticsClients,
    to: `/${ ResourseNames.statisticsClients }`,
    primaryText: 'Статистика по  клиентам',
    leftIcon: <GroupIcon />,
  },
  {
    resourseName: ResourseNames.diseasesGroup,
    to: '/diseases/group',
    primaryText: 'Группы заболеваний',
    leftIcon: <FolderIcon />,
  },
  {
    resourseName: ResourseNames.diseases,
    to: '/diseases',
    primaryText: 'Заболевания',
    leftIcon: <FolderIcon />,
  },
  {
    resourseName: ResourseNames.therapyMethods,
    to: '/therapy_methods',
    primaryText: 'Методы терапии',
    leftIcon: <SpaIcon />,
  },
  {
    resourseName: ResourseNames.faqGroup,
    to: '/faq/group',
    primaryText: 'Faq-группы',
    leftIcon: <LiveHelpIcon />,
  },
  {
    resourseName: ResourseNames.faqQuestions,
    to: '/faq/question',
    primaryText: 'Faq-вопросы',
    leftIcon: <LiveHelpIcon />,
  },{
    resourseName: ResourseNames.statisticsSessions,
    to: '/statistics/sessions',
    primaryText: 'Статистика по сессиям',
    leftIcon: <StackedLineChartIcon />,
  },
  {
    resourseName: ResourseNames.statisticsPromocodes,
    to: '/statistics/therapists/promocodes',
    primaryText: 'Статистика по промокодам',
    leftIcon: <StackedLineChartIcon />,
  },
  {
    resourseName: ResourseNames.currencies,
    to: '/payment-gateway/currencies',
    primaryText: 'Курс валют',
    leftIcon: <BusinessIcon />,
  },
  {
    resourseName: ResourseNames.statisticsPromotedSessions,
    to: '/statistics/sessions-promoted',
    primaryText: 'Промоутируемые сессии',
    leftIcon: <StackedLineChartIcon />,
  },
  {
    resourseName: ResourseNames.reusablePromocodes,
    to: '/promocodes',
    primaryText: 'Именованные промокоды',
    leftIcon: <AttachMoneyIcon />,
  },
  {
    resourseName: ResourseNames.apiKeys,
    to: '/api-keys',
    primaryText: 'Api-ключи',
    leftIcon: <ApiIcon />,
  },
  {
    resourseName: ResourseNames.webhooks,
    to: '/webhooks',
    primaryText: 'Webhooks',
    leftIcon: <WebhookIcon />,
  },
   {
    resourseName: ResourseNames.searchStatistics,
    to: '/search-statistics',
    primaryText: 'Статистика поиска',
    leftIcon: <StackedLineChartIcon />,
  },
  {
    resourseName: ResourseNames.paymentLinkCreate,
    to: '/payment-link/create',
    primaryText: 'Создать ссылку оплаты',
    leftIcon: <BusinessIcon />,
  },
  {
    resourseName: ResourseNames.paymentLinkList,
    to: '/payment-link/list',
    primaryText: 'Список оплат',
    leftIcon: <BusinessIcon />,
  },
];

export const SidebarMenu = () => {
  const { permissions = [] } = usePermissions<PermissionType[]>();

  return (
    <Menu>
      {MENU_ITEMS.reduce<JSX.Element[]>(
        (result, { resourseName, ...menuItemProps }) => {
          const hasAccess = permissions.some((permission) => {
            if ([ResourseNames.apiKeys, ResourseNames.webhooks].includes(resourseName)) {
              return resourseName === permission.resource;
            }

            return ['*', resourseName].includes(permission.resource);
          });

          if (hasAccess) {
            result.push(<Menu.Item {...menuItemProps} />);
          }

          return result;
        },
        [],
      )}
    </Menu>
  );
};
