import { Button } from '@mui/material';
import { useCallback } from 'react';
import {
  TopToolbar,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';

export const TherapistsRequestsShowActions = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleVerifyButtonClick = useCallback(() => {
    httpClient(
      `${API_BASE_URL_ADMIN}${API_URL.therapistsRequestVerify(
        record.id as string,
      )}`,
      {
        method: 'POST',
      },
    )
      .then(() => {
        notify('Заявка принята', { type: 'success' });
        redirect('/therapists/requests');
      })
      .catch((error) => {
        notify(error.message || 'Что-то пошло не так...', {
          type: 'error',
        });
      });
  }, [notify, record.id, redirect]);

  const handleRejectButtonClick = useCallback(() => {
    httpClient(
      `${API_BASE_URL_ADMIN}${API_URL.therapistsRequestReject(
        record.id as string,
      )}`,
      {
        method: 'POST',
      },
    )
      .then(() => {
        notify('Заявка отклонена', { type: 'success' });
        redirect('/therapists/requests');
      })
      .catch((error) => {
        notify(error.message || 'Что-то пошло не так...', {
          type: 'error',
        });
      });
  }, [notify, record.id, redirect]);

  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="success"
        size="small"
        onClick={handleVerifyButtonClick}
      >
        Принять
      </Button>
      <Button
        variant="contained"
        color="error"
        size="small"
        onClick={handleRejectButtonClick}
      >
        Отказать
      </Button>
    </TopToolbar>
  );
};
