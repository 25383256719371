import { TherapistTherapiesInfoRequestDtoSpecialisationEnum } from 'shared/api';

export const getSpecializations = (
  specialization: TherapistTherapiesInfoRequestDtoSpecialisationEnum,
) => {
  switch (specialization) {
    case TherapistTherapiesInfoRequestDtoSpecialisationEnum.Coach:
      return 'Коуч';
    case TherapistTherapiesInfoRequestDtoSpecialisationEnum.Psychoanalyst:
      return 'Психиатр';
    case TherapistTherapiesInfoRequestDtoSpecialisationEnum.Psychologist:
      return 'Психолог';
    case TherapistTherapiesInfoRequestDtoSpecialisationEnum.Psychotherapist:
      return 'Психотерапевт';
    case TherapistTherapiesInfoRequestDtoSpecialisationEnum.Supervisor:
      return 'Супервизор';
    case TherapistTherapiesInfoRequestDtoSpecialisationEnum.ClinicalPsychologist:
      return 'Клинический психолог';

    default:
      break;
  }
};
