import { Datagrid, List, TextField } from 'react-admin';

export const PaymentLinkList = () => {
  return (
    <List actions={undefined}>
    <Datagrid bulkActionButtons={false}>
      <TextField
        source="id"
        label={'id'}
      />
      <TextField
        source="status"
        label={'Статус'}
      />
      <TextField
        source="amount"
        label={'Сумма'}
      />
      <TextField
        source="currency"
        label={'Валюта'}
      />
      <TextField source="description" label={'Описание'} />
      <TextField source="timestamp" label={'Дата'} />
    </Datagrid>
    </List>
  );
};
