import { BooleanField, Datagrid, DateField, List, TextField, downloadCSV } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { TherapistListActions } from './ui/actions';

export const TherapistList = () => {
  return (
    <List actions={<TherapistListActions />}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField
          source="personalInfo.firstname"
          label={LABELS.firstname}
          sortable={false}
        />
        <TextField
          source="personalInfo.lastname"
          label={LABELS.lastname}
          sortable={false}
        />
        <TextField
          source="rewardInPercent"
          label={LABELS.rewardInPercent}
          sortable={false}
        />
        <BooleanField
          source="isTop"
          label={LABELS.isTop}
        />
        <BooleanField
          source="isTest"
          label={LABELS.isTestTherapist}
        />
        <BooleanField
          source="isMedsi"
          label={LABELS.isMedsi}
        />
        <BooleanField
          source="isAdult"
          label={LABELS.isAdult}
        />
        <BooleanField
          source="isChildTherapist"
          label={LABELS.isChildTherapist}
        />
        <BooleanField
          source="isSupervisor"
          label={LABELS.isSupervisorShort}
        />
        <DateField
          source="createdAt"
          label="Зарегистрирован"
          sortable={false}
          showTime
        />
      </Datagrid>
    </List>
  );
};
