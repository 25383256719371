import { Datagrid, List, TextField, DateField, EditButton } from 'react-admin';
import { LABELS } from 'shared/constants/labels';

const ReusablePromocodesList = () => {
  return (
    <List>
      <Datagrid bulkActionButtons={false}>
        <TextField source="code" label="Именование" sortable={false} />
        <TextField
          source="amount"
          label={LABELS.promocodeAmount}
          sortable={false}
        />
        <TextField
          source="percentage"
          label={LABELS.promocodePercentage}
          sortable={false}
        />
        <TextField
          source="timesUsed"
          label="Количество использования"
          sortable={false}
        />
        <DateField
          source="expireDate"
          label={LABELS.promocodeExpireDate}
          sortable={false}
          showTime
        />
        <DateField
          source="createdAt"
          label="Дата создания"
          sortable={false}
          showTime
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ReusablePromocodesList;
