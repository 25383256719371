import { FC } from 'react';
import { Button } from '@mui/material';
import { useToggleBan } from './model';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

interface Props {
  id?: string;
  isBan?: boolean;
  banUrl: string;
  unbanUrl: string;
}

export const ToggleBan: FC<Props> = ({ isBan, banUrl, unbanUrl, id }) => {
  const { handleToggleBan, isLoading } = useToggleBan({
    id,
    isBan,
    banUrl,
    unbanUrl,
  });

  return isBan ? (
    <Button
      variant="contained"
      color="success"
      startIcon={<DoNotDisturbOffIcon />}
      size="small"
      onClick={handleToggleBan}
      disabled={isLoading}
    >
      Unban
    </Button>
  ) : (
    <Button
      variant="contained"
      color="error"
      startIcon={<DoNotDisturbOnIcon />}
      size="small"
      onClick={handleToggleBan}
      disabled={isLoading}
    >
      Ban
    </Button>
  );
};
