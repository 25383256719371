import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
  isApproved?: boolean;
  approveUrl: string;
  unapproveUrl: string;
}

export const useToggleApprove = ({
  isApproved,
  approveUrl,
  unapproveUrl,
  id,
}: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
    isApproved ? unapproveUrl : approveUrl,
    {
      id,
    },
    {
      onSettled: () => {
        refresh();
      },
    },
  );

  const handleToggleApprove = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    toggle,
    handleToggleApprove,
    isLoading,
    error,
  };
};
