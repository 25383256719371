import { useEffect, useMemo, useState } from 'react';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { httpClient } from 'shared/services/http-client';
import { Query } from 'shared/types';

interface Options {
  skip?: boolean;
}

export const useHttpClient = <Response>(url: string, options?: Options) => {
  const [{ isLoading, error, data }, setQuery] = useState<Query<Response>>({});

  const effect = () => {
    setQuery({ isLoading: true });
    httpClient(`${API_BASE_URL_ADMIN}${url}`)
      .then((res) => {
        setQuery({ data: res.json });
      })
      .catch((error) => {
        setQuery({ error });
      })
      .finally(() => {
        setQuery((prevValue) => ({ ...prevValue, isLoading: false }));
      });
  };

  useEffect(() => {
    if (options?.skip) return;

    effect();
  }, [url, options?.skip]);

  return useMemo(
    () => ({ isLoading, error, data, refetch: effect }),
    [isLoading, error, data],
  );
};
