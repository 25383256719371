import { useEffect, useMemo, useState } from 'react';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import styled from 'styled-components';
import { useNotify, CheckboxGroupInput } from 'react-admin';

const Webhook = () => {
  const notify = useNotify();


  const [touched, setTouched] = useState<boolean>(false);
  const [webhookUrl, setWebhookUrl] = useState<string>('');
  const [errorText, setErrorText] = useState<string>();
  const [eventTypes, setEventTypes] = useState<{
    userCreated: boolean,
    sessionBooked: boolean,
    sessionCanceled: boolean,
    sessionMoved: boolean,
  }>({
    userCreated: false,
    sessionBooked: false,
    sessionCanceled: false,
    sessionMoved: false,
  });

  const validateUrl = (url: string): boolean => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // протокол
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // доменное имя
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?' + // порт
        '(\\/[-a-z\\d%_.~+]*)*' + // путь
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // строка запроса
        '(\\#[-a-z\\d_]*)?$', 'i'); // якорь

    return !!pattern.test(url);
}

  const getWebhookSettings = () => {
    try {
      const settings = localStorage.getItem('webhook_settings');

      if (!settings) {
        return;
      }

      const parsedSettings = JSON.parse(settings);

      setWebhookUrl(parsedSettings.webhookUrl);
      setEventTypes(parsedSettings.eventTypes);
    } catch (err) {
      localStorage.removeItem('webhook_settings');
    }
  }

  const saveWebhookSettings = () => {
    if (webhookUrl?.length) {
      const isValidUrl = validateUrl(webhookUrl);

      if (!isValidUrl) {
        setTouched(false);
        return setErrorText('Не валидный url');
      }
    }

    localStorage.setItem('webhook_settings', JSON.stringify({ webhookUrl, eventTypes }));

    notify('Настройки вебхуков сохранены', { type: 'success' });
    setTouched(false);
  }

  useEffect(() => getWebhookSettings(), []);

  const handleChangeEventType = (e: any) => {
    setEventTypes(prev => ({ ...prev, [e.target.title]: e.target.checked }));
    setTouched(true);
  }

  const handleWebhookUrlChange = (e: any) => {
    setWebhookUrl(e.target.value);
    setTouched(true);
  }

  return (
    <Root>
      <StyledApiKey>
        <StyledInput value={webhookUrl} placeholder='https://placeholder.example/hanle-event' onChange={handleWebhookUrlChange} />
      </StyledApiKey>
      <StyledEventTypes>
        <StyledEventCheckboxLabel>User created:
          <StyledEventTypeCheckbox type="checkbox" title='userCreated' checked={eventTypes.userCreated} onChange={handleChangeEventType} />
        </StyledEventCheckboxLabel>
        <StyledEventCheckboxLabel>Session booked:
          <StyledEventTypeCheckbox type="checkbox" title='sessionBooked' checked={eventTypes.sessionBooked} onChange={handleChangeEventType} />
        </StyledEventCheckboxLabel>
        <StyledEventCheckboxLabel>Session canceled:
          <StyledEventTypeCheckbox type="checkbox" title='sessionCanceled' checked={eventTypes.sessionCanceled} onChange={handleChangeEventType} />
        </StyledEventCheckboxLabel>
        <StyledEventCheckboxLabel>Session moved:
          <StyledEventTypeCheckbox type="checkbox" title='sessionMoved' checked={eventTypes.sessionMoved} onChange={handleChangeEventType} />
        </StyledEventCheckboxLabel>
      </StyledEventTypes>
      {errorText && <StyledErrorText>{errorText}</StyledErrorText>}
      <RefreshBtn disabled={!touched} onClick={saveWebhookSettings}>Обновить</RefreshBtn>
    </Root>
  );
};

export default Webhook;

const Root = styled.div`
  padding: 20px;
`;

const StyledApiKey = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  background-color: #eee;
`

const RefreshBtn = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 25px;
  border: none;
  padding: 0 10px;
`
const StyledErrorText = styled.div`
  color: red;
`

const StyledEventCheckboxLabel = styled.label`
  margin-right: 20px;
`

const StyledEventTypes = styled.div`
  margin-top: 15px;
`

const StyledEventTypeCheckbox = styled.input``