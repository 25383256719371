import { FC } from "react";
import numberSpaces from "shared/utils/numbers-with-spaces";
import prettyFloat from "shared/utils/pretty-float";
import styled from "styled-components";

interface Props {

  title: string
  medsi: number | string
  pogovorim: number | string
  inPercent?: boolean
  middleAllValue?: boolean
  withoutAllValue?: boolean

}

const StatRow: FC<Props> = ( props ) => {

  const { 

    title, 
    medsi, 
    pogovorim, 
    middleAllValue = false, 
    inPercent = false, 
    withoutAllValue = false 

  } = props;

  const medsiValue = withoutAllValue ? medsi : numberSpaces( prettyFloat( +medsi ) );
  const pogovorimValue = withoutAllValue ? pogovorim : numberSpaces( prettyFloat( +pogovorim ) );

  const allValue = withoutAllValue ? "" : numberSpaces( 
    
    prettyFloat( 

      middleAllValue
        ? ( +medsi + +pogovorim ) / 2
        : +medsi + +pogovorim 
        
    )

  );
  
  return (

    <Grid>

      <span>{ title }</span>
      <span>{ medsiValue }{ inPercent ? '%' : '' }</span>
      <span>{ pogovorimValue }{ inPercent ? '%' : '' }</span>
      <span>{ allValue }{ inPercent ? '%' : '' }</span>

    </Grid>

  );

}

const StatHead: FC = () => (

  <Grid>

    <span/>
    <span>Медси</span>
    <span>Pogovorim</span>
    <span>Всего</span>

  </Grid>

);

export {
  
  StatRow,
  StatHead

};

const Grid = styled.div`

  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  padding: 10px 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  & > :not(:first-child) {
    text-align: end;
  }

  & > :last-child {
    font-weight: 700;
  }

  &:nth-child(2n+3) {
    background-color: #efefef;
  }

`;