import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Button } from '@mui/material';
import { useToggleIsChild } from 'features/toggle-is-child/model';
import { FC } from 'react';

interface Props {
  id?: string;
  isChild?: boolean;
  isChildUrl: string;
}

export const ToggleIsChild: FC<Props> = ({ isChild, isChildUrl, id }) => {
  const { handleToggleIsTest, isLoading } = useToggleIsChild({
    isChild,
    id,
    isChildUrl,
  });

  return !isChild ? (
    <Button
      variant="contained"
      color="error"
      startIcon={<UnpublishedIcon />}
      size="small"
      onClick={handleToggleIsTest}
      disabled={isLoading}
    >
      CHILD
    </Button>
  ) : (
    <Button
      variant="contained"
      color="success"
      startIcon={<CheckCircleIcon />}
      size="small"
      onClick={handleToggleIsTest}
      disabled={isLoading}
    >
      CHILD
    </Button>
  );
};
