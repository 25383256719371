import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
  isBan?: boolean;
  banUrl: string;
  unbanUrl: string;
}

export const useToggleBan = ({ isBan, banUrl, unbanUrl, id }: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
    isBan ? unbanUrl : banUrl,
    {
      id,
    },
    {
      onSettled: () => {
        refresh();
      },
    }
  );
  const handleToggleBan = useCallback(() => {
    toggle();
  }, [toggle]);

  return { toggle, handleToggleBan, isLoading, error };
};
