import { FC } from 'react';
import { Button } from '@mui/material';
import { useToggleIsTop } from './model';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';


interface Props {
	id?: string;
	isTop?: boolean;
	isTopUrl: string;
}

export const ToggleIsTop: FC<Props> = ({
	isTop,
	isTopUrl,
	id,
}) => {
	const { handleToggleIsTop, isLoading } = useToggleIsTop({
		isTop,
		id,
		isTopUrl,
	});

	return !isTop ? (
		<Button
			variant="contained"
			color="error"
			startIcon={<UnpublishedIcon />}
			size="small"
			onClick={handleToggleIsTop}
			disabled={isLoading}
		>
			Top
		</Button>
	) : (
		<Button
			variant="contained"
			color="success"
			startIcon={<CheckCircleIcon />}
			size="small"
			onClick={handleToggleIsTop}
			disabled={isLoading}
		>
			Top
		</Button>
	);
};
