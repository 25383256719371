import { BooleanField, Datagrid, DateField, EmailField, List, TextField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { PatientsListActions } from './ui/actions';

export const PatientsList = () => {
  return (
    <List actions={<PatientsListActions />} hasShow>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField
          source="utmCampaign"
          sortable={false}
          label={LABELS.utmCampaign}
        />
        <TextField
          source="utmMedium"
          sortable={false}
          label={LABELS.utmMedium}
        />
        <TextField
          source="utmSource"
          sortable={false}
          label={LABELS.utmSource}
        />
        <TextField
          source="firstname"
          sortable={false}
          label={LABELS.firstname}
        />
        <TextField source="lastname" label={LABELS.lastname} />
        <EmailField source="email" label={LABELS.email} sortable={false} />
        <TextField source="phone" label={LABELS.phone} sortable={false} />
        <DateField
          source="dateOfBirth"
          label={LABELS.dateOfBirth}
          sortable={false}
        />
        <BooleanField
          source="isCard"
          label={LABELS.isCardAssigned}
          sortable={false}
        />
        <BooleanField
          source="isTest"
          label={LABELS.isTestPatient}
          sortable={false}
        />
        <DateField
          source="createdAt"
          label="Зарегистрирован"
          sortable={false}
          showTime
        />
      </Datagrid>
    </List>
  );
};
