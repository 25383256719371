import { Loading, Show, useGetOne, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { AdminTherapistProfileUpdateResponseDto } from 'shared/api';
import { TherapistProfileUpdateShowActions } from './ui/actions';
import { TherapistsProfileUpdatesShowContent } from './ui/content';

export const TherapistProfileUpdatesShow = () => {
  const { id } = useParams();

  const redirect = useRedirect();
  const { data, isLoading } = useGetOne<AdminTherapistProfileUpdateResponseDto>(
    'therapists/profile-updates',
    { id: id as string },
    { onError: () => redirect('/therapists/profile-updates') },
  );
  if (isLoading) {
    return <Loading />;
  }

  if(!data) return null;
  
  const pageTitle = `${data?.firstname} ${data?.lastname}`;

  return (
    <Show title={pageTitle} actions={<TherapistProfileUpdateShowActions />}>
      <TherapistsProfileUpdatesShowContent data={data} />
    </Show>
  );
};
