import { useEffect, useState } from 'react';
import {
  FunctionField,
  Loading,
  ReferenceField,
  ReferenceOneField,
  Show,
  SimpleShowLayout,
  TextField,
  useGetOne,
  useNotify,
  useRecordContext,
  useRedirect,
  useShowContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { AdminDiseaseGroupResponseDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { httpClient } from 'shared/services/http-client';

export const DiseasesShow = () => {
  const { id } = useParams();
  const { data: diseasesData, isLoading } = useHttpClient(
    `${API_URL.diseasesById(id!)}`,
    { skip: !id },
  );
  const notify = useNotify();

  const [deseasesGroup, setDiseasesGroup] = useState<string>('');
  const [isLoadingDeseasesGroup, setIsLoadingDeseasesGroup] = useState(false);

  useEffect(() => {
    if (diseasesData) {
      setIsLoadingDeseasesGroup(true);
      httpClient(
        `${API_BASE_URL_ADMIN}${API_URL.diseasesGroupById(
          diseasesData.diseasesGroupId!,
        )}`,
      )
        .then((response) => {
          const result = response.json as AdminDiseaseGroupResponseDto;
          setDiseasesGroup(result.name);
          setIsLoadingDeseasesGroup(false);
        })
        .catch(() => {
          notify('Что-то пошло не так...', { type: 'error' });
        });
    }
  }, [diseasesData, notify]);

  if (isLoading || isLoadingDeseasesGroup) {
    return <Loading />;
  }

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" label={LABELS.name} />
        <TextField source="description" label={LABELS.description} />
        <FunctionField
          label={LABELS.diseasesGroup}
          render={() => deseasesGroup}
        />
      </SimpleShowLayout>
    </Show>
  );
};
