import { Edit, required, SimpleForm, TextInput } from 'react-admin';
import { LABELS } from 'shared/constants/labels';

export const FaqGroupEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput
          source="title"
          label={LABELS.title}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};
