import { getUrl } from './get-url';
import { httpClient } from 'shared/services/http-client';
import { API_URL } from 'shared/constants/api-url';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { CreateFileRequestDtoMediaTypeEnum } from 'shared/api';
import { API_BASE_URL } from 'shared/config';

export async function uploadFile(
  file: File,
  mediaType: CreateFileRequestDtoMediaTypeEnum = 'documents',
  maxSize: number | undefined = 100000000,
) {
  const { size, type, name } = file;
  const cyrillicToTranslit = CyrillicToTranslit();

  if (size > maxSize) {
    return { filename: undefined, key: undefined };
  }

  const body = JSON.stringify({
    contentType: type,
    fileName: cyrillicToTranslit.transform(name, '-').toLowerCase(),
    mediaType,
  });

  const signRes = await httpClient(
    `${API_BASE_URL}${API_URL.getPublicAssignedUrl}`,
    { method: 'POST', body },
  );

  await httpClient(signRes.body, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': type,
      'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
      'Access-Control-Allow-Origin': '*',
    }),
    body: file,
  });

  return { filename: name, key: getUrl(signRes.body) };
}
