import { SupervisionRequestResponseDtoTypeEnum } from 'shared/api';
import { VARS } from 'shared/constants/vars';

export const getSupervisionType = (
  types?: SupervisionRequestResponseDtoTypeEnum[],
) => {
  return types
    ? types.map((type) => VARS.russianTranslateSupervisionType[type]).join(', ')
    : '';
};
