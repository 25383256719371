import { useEffect } from 'react';
import {
  Datagrid,
  DateField,
  ListContextProvider,
  Loading,
  TextField,
  useList,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { GetPromocodesResponseDto } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { useHttpClient } from 'shared/hooks/useHttpClient';

const Promocodes = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { isLoading, data } = useHttpClient<GetPromocodesResponseDto>(
    API_URL.companyPromocodes(id!),
    { skip: !id },
  );
  const packagesWithId = data?.packages.map((item, index) => ({
    ...item,
    id: index,
  }));
  const listContext = useList({ data: packagesWithId, isLoading });

  useEffect(() => {
    if (!id && !data) redirect('/companies');
  }, []);

  if (isLoading) return <Loading />;

  if (!data) return null;

  return (
    <>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="amount" label={LABELS.promocodeAmount} />
          <DateField
            sortable={false}
            source="expireDate"
            label={LABELS.promocodeExpireDate}
          />
          <TextField source="total" label={LABELS.promocodeQuantity} />
          <TextField source="used" label="Использовано" />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};

export default Promocodes;
