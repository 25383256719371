import {
  CreateButton,
  ExportButton,
  SortButton,
  TopToolbar,
} from 'react-admin';
import { SORTING_VALUES } from 'shared/constants/sorting-values';
import XLSX from 'xlsx'

export const CompaniesListActions = () => (
  <TopToolbar>
    <SortButton fields={[SORTING_VALUES.createdAt, SORTING_VALUES.name]} />
    <CreateButton />
    <ExportButton exporter={(...args) => {
      const worksheet = XLSX.utils.json_to_sheet(args[0], { sheetStubs: true });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

      worksheet["!cols"] = new Array(50).fill({ width: 40 });
      XLSX.writeFile(workbook, "Companies.xlsx", { compression: true });
    }} />
  </TopToolbar>
);
