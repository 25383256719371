import { useCreateReusablePromocode } from 'features/create-reusable-promocode';
import {
  BooleanInput,
  Create,
  DateInput,
  Form,
  NumberInput,
  required,
  SaveButton,
  TextInput,
} from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import styled from 'styled-components';
import { useState } from 'react';

export const ReuseablePromocodesCreate = () => {
  const { createReuseablePromocode, isLoading } = useCreateReusablePromocode();

  const [isPercentagePromocode, setPercentagePromocode] = useState(false);

  const handleSubmit = ({
    expireDate,
    amount,
    percentage,
    firstUseOnly,
    code,
  }: any) => {
    if (expireDate) {
      const promocodeExpireDate = new Date(expireDate);
      promocodeExpireDate.setHours(15);

      if (isPercentagePromocode) {
        createReuseablePromocode({
          percentage,
          code,
          expireDate: promocodeExpireDate.toISOString(),
          firstUseOnly,
        });
      } else {
        createReuseablePromocode({
          amount,
          code,
          expireDate: promocodeExpireDate.toISOString(),
          firstUseOnly,
        });
      }
    } else {
      if (isPercentagePromocode) {
        createReuseablePromocode({
          percentage,
          code,
          firstUseOnly,
        });
      } else {
        createReuseablePromocode({
          amount,
          code,
          firstUseOnly,
        });
      }
    }
  };

  return (
    <Create title="Новый промокод">
      <FormWrapper>
        <Form onSubmit={handleSubmit}>
          <BooleanInput
            label="Промокод в процентах"
            onChange={() => {
              setPercentagePromocode(!isPercentagePromocode);
            }}
            source="isPercentagePromocode"
          />
          {isPercentagePromocode ? (
            <NumberInput
              source="percentage"
              label={LABELS.promocodePercentage}
              fullWidth
              validate={[required()]}
              min={1}
              max={99}
            />
          ) : (
            <NumberInput
              source="amount"
              label={LABELS.promocodeAmount}
              fullWidth
              validate={[required()]}
            />
          )}
          <TextInput
            source="code"
            label="Именование"
            fullWidth
            validate={[required()]}
          />
          <DateInput
            label={LABELS.promocodeExpireDate}
            source="expireDate"
            fullWidth
          />
          <BooleanInput
            label={LABELS.promocodeFirstUseOnly}
            source="firstUseOnly"
          />
          <SaveButton label="Создать" disabled={isLoading} />
        </Form>
      </FormWrapper>
    </Create>
  );
};

const FormWrapper = styled.div`
  padding: 20px;
`;
