import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { AdminCompanyResponseDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';

export const useToggleArchiveCompany = ({
  isArchive,
  id,
}: AdminCompanyResponseDto) => {
  const [isLoading, setIsLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const url = isArchive
    ? API_URL.unarchiveCompany(id)
    : API_URL.archiveCompany(id);

  const toggleArchiveCompany = () => {
    setIsLoading(true);
    httpClient(`${API_BASE_URL_ADMIN}${url}`, { method: 'PATCH' })
      .then(() => {
        refresh();
        const message = isArchive
          ? 'Компания убрана из архива'
          : 'Компания теперь в архиве';
        notify(message, { type: 'success' });
      })
      .catch((error) => {
        notify(error.message || 'Что-то пошло не так...', { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { toggleArchiveCompany, isLoading };
};
