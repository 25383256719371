import { useEffect, useState } from 'react';
import {
  Edit,
  Loading,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  AdminDiseaseGroupResponseDto,
  AdminDiseaseGroupsResponseDto,
} from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { httpClient } from 'shared/services/http-client';

interface DiseasesGroupOption {
  id: string;
  name: string;
}

export const DiseasesEdit = () => {
  const { id } = useParams();
  const notify = useNotify();
  const { data: diseasesData, isLoading } = useHttpClient(
    `${API_URL.diseasesById(id!)}`,
    { skip: !id },
  );
  const [deseasesGroupOptions, setDiseasesGroupOptions] = useState<
    DiseasesGroupOption[]
  >([]);
  const [isLoadingDeseasesGroup, setIsLoadingDeseasesGroup] = useState(false);

  useEffect(() => {
    if (diseasesData) {
      setIsLoadingDeseasesGroup(true);
      httpClient(`${API_BASE_URL_ADMIN}${API_URL.diseasesGroup}`)
        .then((response) => {
          const result = response.json as AdminDiseaseGroupsResponseDto;
          setDiseasesGroupOptions(
            result.data.map(({ id, name }) => ({ id, name })),
          );
          setIsLoadingDeseasesGroup(false);
        })
        .catch(() => {
          notify('Что-то пошло не так...', { type: 'error' });
        });
    }
  }, [diseasesData, notify]);

  if (isLoading || isLoadingDeseasesGroup) {
    return <Loading />;
  }

  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" fullWidth label={LABELS.name} />
        <TextInput source="description" fullWidth label={LABELS.description} />
        <SelectInput
          source="diseasesGroupId"
          label={LABELS.diseasesGroup}
          choices={deseasesGroupOptions}
          fullWidth
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
