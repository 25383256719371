import { useCallback, useEffect, useState } from 'react';
import { useRefresh, useUpdate } from 'react-admin';


interface IUseRewardInPercentParams {
	id?: string;
	rewardInPercentUrl: string;
	currentPercent: number
}

export const useRewardInPercent = ({ rewardInPercentUrl, id, currentPercent }: IUseRewardInPercentParams) => {
	const refresh = useRefresh();
	
	const [ prompt, setPrompt ] = useState<number | null>(null)
	
	const [ toggle, { isLoading, error } ] = useUpdate(
	 rewardInPercentUrl,
	 {
		 id,
		 data: { rewardInPercent: prompt },
	 },
	 {
		 onSettled: () => {
			 refresh();
		 },
	 },
	);
	
	const handleToggle = useCallback(() => {
		const userInput = window.prompt('Введите значение от 0 до 100', String(currentPercent));
		const value = userInput !== null ? Number(userInput) : null;
		
		if( value === null ) {
			return
		}
		
		if( value < 0 || value > 100 || isNaN(value) ) {
			alert('Ошибка: значение должно быть от 0 до 100.');
			return
		}
		
		setPrompt(value);
	}, []);
	
	useEffect(() => {
		if( prompt !== null ) {
			toggle();
			setPrompt(null);
		}
	}, [ prompt ]);
	
	return {
		toggle,
		handleToggle,
		isLoading,
		error,
	};
};

interface IUseSessionPriceParams {
	id?: string;
	priceUrl: string;
	currentPrice: number
}

export const useSessionPrice = ({ priceUrl, id, currentPrice }: IUseSessionPriceParams) => {
	const refresh = useRefresh();
	
	const [ prompt, setPrompt ] = useState<number | null>(null)
	
	const [ toggle, { isLoading, error } ] = useUpdate(
		priceUrl,
	 {
		 id,
		 data: { price: prompt },
	 },
	 {
		 onSettled: () => {
			 refresh();
		 },
	 },
	);
	
	const handleToggle = useCallback(() => {
		const userInput = window.prompt('Введите значение стоимости сессии', String(currentPrice));
		const value = userInput !== null ? Number(userInput) : null;
		
		if( value === null ) {
			return
		}
		
		// if( value < 0 || value > 100 || isNaN(value) ) {
		// 	alert('Ошибка: значение должно быть от 0 до 100.');
		// 	return
		// }
		
		setPrompt(value);
	}, []);
	
	useEffect(() => {
		if( prompt !== null ) {
			toggle();
			setPrompt(null);
		}
	}, [ prompt ]);
	
	return {
		toggle,
		handleToggle,
		isLoading,
		error,
	};
};

interface IShortAboutParams {
	id?: string;
	shortAboutUrl: string;
	shortAbout: string
}

export const useShortAbout = ({ shortAboutUrl, id, shortAbout }: IShortAboutParams) => {
	const refresh = useRefresh();
	
	const [ prompt, setPrompt ] = useState<string | null>(null)
	
	const [ toggle, { isLoading, error } ] = useUpdate(
		shortAboutUrl,
	 {
		 id,
		 data: { shortAbout: prompt },
	 },
	 {
		 onSettled: () => {
			 refresh();
		 },
	 },
	);
	
	const handleToggle = useCallback(() => {
		const userInput = window.prompt('Введите короткое описание терапевта', String(shortAbout));
		const value = userInput !== null ? userInput : null;
		
		if( value === null ) {
			return
		}
		
		setPrompt(value);
	}, []);
	
	useEffect(() => {
		if( prompt !== null ) {
			toggle();
			setPrompt(null);
		}
	}, [ prompt ]);
	
	return {
		toggle,
		handleToggle,
		isLoading,
		error,
	};
};

interface IAboutParams {
	id?: string;
	aboutUrl: string;
	about: string
}

export const useAbout = ({ aboutUrl, id, about }: IAboutParams) => {
	const refresh = useRefresh();
	
	const [ prompt, setPrompt ] = useState<string | null>(null)
	
	const [ toggle, { isLoading, error } ] = useUpdate(
		aboutUrl,
	 {
		 id,
		 data: { about: prompt },
	 },
	 {
		 onSettled: () => {
			 refresh();
		 },
	 },
	);
	
	const handleToggle = useCallback(() => {
		const userInput = window.prompt('Введите описание терапевта', String(about));
		const value = userInput !== null ? userInput : null;
		
		if( value === null ) {
			return
		}
		
		setPrompt(value);
	}, []);
	
	useEffect(() => {
		if( prompt !== null ) {
			toggle();
			setPrompt(null);
		}
	}, [ prompt ]);
	
	return {
		toggle,
		handleToggle,
		isLoading,
		error,
	};
};
