import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
  isChild?: boolean;
  isChildUrl: string;
}

export const useToggleIsChild = ({ isChild, isChildUrl, id }: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
    isChildUrl,
    {
      id,
      data: { isChildTherapist: !isChild },
    },
    {
      onSettled: () => {
        refresh();
      },
    },
  );

  const handleToggleIsTest = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    toggle,
    handleToggleIsTest,
    isLoading,
    error,
  };
};
