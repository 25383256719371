import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Button } from '@mui/material';
import { FC } from 'react';
import { useToggleIsSupervisor } from './model';

interface Props {
  id?: string;
  isSupervisor?: boolean;
  isSupervisorUrl: string;
}

export const ToggleIsSupervisor: FC<Props> = ({ isSupervisor, isSupervisorUrl, id }) => {
  const { handleToggleIsSupervisor, isLoading } = useToggleIsSupervisor({
    isSupervisor,
    id,
    isSupervisorUrl,
  });

  return !isSupervisor ? (
    <Button
      variant="contained"
      color="error"
      startIcon={<UnpublishedIcon />}
      size="small"
      onClick={handleToggleIsSupervisor}
      disabled={isLoading}
    >
      SUPERVISOR
    </Button>
  ) : (
    <Button
      variant="contained"
      color="success"
      startIcon={<CheckCircleIcon />}
      size="small"
      onClick={handleToggleIsSupervisor}
      disabled={isLoading}
    >
      SUPERVISOR
    </Button>
  );
};
