import { Datagrid, List, TextField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';

export const FaqQuestionsList = () => {
  return (
    <List pagination={false}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="title" label={LABELS.question} sortable={false} />
        <TextField source="text" label={LABELS.answer} sortable={false} />
      </Datagrid>
    </List>
  );
};
