import {
  CreateButton,
  ExportButton,
  SortButton,
  TopToolbar,
} from 'react-admin';
import { SORTING_VALUES } from 'shared/constants/sorting-values';
import XLSX from 'xlsx'

export const PatientsListActions = () => (
  <TopToolbar>
    <SortButton
      fields={[
        SORTING_VALUES.createdAt,
        SORTING_VALUES.lastname,
        SORTING_VALUES.sessionsCompleted,
        SORTING_VALUES.sessionsAwaiting,
      ]}
    />
    <CreateButton />
    <ExportButton maxResults={100000} exporter={(...args) => {
      const worksheet = XLSX.utils.json_to_sheet(args[0], { sheetStubs: true });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

      worksheet["!cols"] = [ { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 }, { width: 40 } ];
      XLSX.writeFile(workbook, "Patients.xlsx", { compression: true });
    }} />
  </TopToolbar>
);
