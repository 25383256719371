import { useEffect, useState } from 'react';
import {
  Datagrid,
  Form,
  ListContextProvider,
  Loading,
  SaveButton,
  SelectInput,
  TextField,
  useList,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { GetStatisticsResponseDto } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { VARS } from 'shared/constants/vars';
import { getYearRange } from 'shared/utils/get-year-range';
import Button from '@mui/material/Button';
import { convertObjArrayToCsv } from 'shared/utils/convert-array-to-csv';
import { downloadText } from 'shared/utils/download-text';

const incrementFutureYears = 5;
const yearChoices = getYearRange(
  new Date().getFullYear() + incrementFutureYears,
  2022,
).map((item) => ({ id: item, name: item }));

const currentYearChoice = yearChoices.at(-incrementFutureYears - 1)!.name;

const Statistic = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const [year, setYear] = useState(currentYearChoice);
  const { isLoading, data } = useHttpClient<GetStatisticsResponseDto>(
    API_URL.companyStatistic(id!, year),
    { skip: !id },
  );
  const monthsWithId = data?.months.map((item, index) => ({
    ...item,
    id: index,
    month: VARS.monthNames[index],
  }));
  const listContext = useList({ data: monthsWithId, isLoading });

  useEffect(() => {
    if (!id && !data) redirect('/companies');
  }, []);

  if (isLoading) return <Loading />;

  if (!data) return null;

  const handleSubmit = (data: any) => {
    if (data?.year) setYear(data.year);
  };

  const handleDownload = () => {
    const dataWithMonthNames = data?.months.map((item, index) => ({
      ...item,
      month: VARS.monthNames[index],
    }));

    const statisticsCsv = convertObjArrayToCsv(dataWithMonthNames);
    const statisticsCsvWithTranslatedColumns = statisticsCsv
      .replace('month', 'Месяц')
      .replace('total', 'Активные промокоды')
      .replace('expire', 'Истекло');
    downloadText(
      statisticsCsvWithTranslatedColumns,
      'text/comma-separated-values,application/csv,text/csv,.csv',
      `stats_${new Date().getTime()}.csv`,
    );
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <SelectInput
          emptyValue={undefined}
          source="year"
          choices={yearChoices}
          defaultValue={year}
          label="Год"
        />
        <SaveButton
          sx={{ display: 'block', marginBottom: 2 }}
          label="Сменить год"
          icon={<></>}
        />
      </Form>
      <Button
        sx={{ maxWidth: 139, marginBottom: 5 }}
        variant="text"
        color="info"
        onClick={handleDownload}
      >
        Скачать
      </Button>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="month" label="Месяц" />
          <TextField source="total" label="Активные промокоды" />
          <TextField source="expire" label="Истекло" />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};

export default Statistic;
