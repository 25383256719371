import { useState } from 'react';
import { useNotify } from 'react-admin';
import { UpdatePromocodeRequestDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';

export const useUpdateReusablePromocode = ({
  id,
}: {
  id: string | undefined;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();

  const url = API_URL.updateReuseablePromocode(id ?? '');

  const updateReuseablePromocode = (data: UpdatePromocodeRequestDto) => {
    setIsLoading(true);
    httpClient(`${API_BASE_URL_ADMIN}${url}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
      .then(() => {
        notify('Промокод успешно обновлен', { type: 'success' });
      })
      .catch((error) => {
        notify(error.message || 'Что-то пошло не так...', { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { updateReuseablePromocode, isLoading };
};
