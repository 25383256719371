import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
  isAdult?: boolean;
  isAdultUrl: string;
}

export const useToggleIsAdult = ({ isAdult, isAdultUrl, id }: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
    isAdultUrl,
    {
      id,
      data: { isAdult: !isAdult },
    },
    {
      onSettled: () => {
        refresh();
      },
    },
  );

  const handleToggleIsAdult = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    toggle,
    handleToggleIsAdult,
    isLoading,
    error,
  };
};
