import { TherapistPersonalInfoRequestDtoGenderEnum } from 'shared/api';

export const getGender = (
  gender?: TherapistPersonalInfoRequestDtoGenderEnum
) => {
  switch (gender) {
    case TherapistPersonalInfoRequestDtoGenderEnum.Female:
      return 'Женщина';

    case TherapistPersonalInfoRequestDtoGenderEnum.Male:
      return 'Мужчина';

    case TherapistPersonalInfoRequestDtoGenderEnum.Transgender:
      return 'Трансгендер';

    case TherapistPersonalInfoRequestDtoGenderEnum.Unknown:
      return 'Неопределенный';

    default:
      break;
  }
};
