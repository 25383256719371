import { fetchUtils } from 'react-admin';
import { replaceQueryParams } from 'shared/utils/replace-query-params';

export const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const neededUrl = new URL(url);
  const replacedQueryParams = replaceQueryParams(neededUrl.search);
  neededUrl.search = replacedQueryParams;

  const token = localStorage.getItem('token');
  //@ts-ignore
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(neededUrl, options);
};
