import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
		isTop?: boolean;
		isTopUrl: string;
}

export const useToggleIsTop = ({
		isTop,
		isTopUrl,
  id,
}: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
				isTopUrl,
    {
      id, data: { isTop: !isTop }
    },
    {
      onSettled: () => {
        refresh();
      },
    },
  );

  const handleToggleIsTop = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    toggle,
				handleToggleIsTop,
    isLoading,
    error,
  };
};
