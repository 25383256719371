import { Datagrid, List, TextField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';

export const TherapistProfileUpdatesList = () => {
  return (
    <List>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField
          source="firstname"
          label={LABELS.firstname}
          sortable={false}
        />
        <TextField source="lastname" label={LABELS.lastname} sortable={false} />
      </Datagrid>
    </List>
  );
};
